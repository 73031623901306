import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PersonIcon from '@material-ui/icons/Person';
import DnsIcon from '@material-ui/icons/Dns';
import { selectUser, startEditUser, restart } from '../redux/userActions';
import { requestAuthToken, showAuthToken } from '../redux/actions';
import { fetchVersionList, setPicture } from '../redux/versionsActions';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CachedIcon from '@material-ui/icons/Cached';
import { fetchGameDesign } from '../redux/playerActions';
import { showPurchaseSearch } from '../redux/purchaseRecord';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { ExitToApp } from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
// function setCookie(name, value, days) {
//   let expires = '';
//   if (days) {
//     const date = new Date();
//     date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
//     expires = '; expires=' + date.toUTCString();
//   }
//   document.cookie = name + '=' + (value || '') + expires + '; path=/; SameSite=Strict; Secure';
// }

export const MainMenu = () => {
  const player = useSelector((state) => state.player);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [cookies, _, removeCookie] = useCookies(['auth']);
  const navigate = useNavigate();
  const [state, setState] = useState({ menuOpen: false, anchorEl: null });
  const handleToggle = (e) => {
    setState({ ...state, anchorEl: e.currentTarget, menuOpen: !state.menuOpen });
  };
  const handleNewUser = () => {
    setState({ ...state, anchorEl: null, menuOpen: false });
    dispatch(selectUser({ Name: '', Login: '', Pwd: '' }));
  };
  const handleEditUser = () => {
    setState({ ...state, anchorEl: null, menuOpen: false });
    dispatch(startEditUser());
  };
  const handleLogOut = () => {
    console.log(_);
    // решшить данный вопрос
    removeCookie('auth');
    navigate('/');
    console.log(cookies);
  };
  const handleClose = () => {
    setState({ ...state, anchorEl: null, menuOpen: false });
  };
  const handleVersionList = () => {
    setState({ ...state, anchorEl: null, menuOpen: false });
    dispatch(fetchVersionList());
    navigate('/version');
  };
  const handleRestart = () => {
    dispatch(restart());
    setState({ ...state, menuOpen: false });
  };
  const getAuthToken = () => {
    handleClose();
    dispatch(requestAuthToken());
  };
  const copyAuthToken = () => {
    navigator.clipboard.writeText(user.authToken);
  };
  const hideAuthToken = () => {
    console.log('hiding');
    dispatch(showAuthToken(''));
  };
  const handleOpenBalance = () => {
    window.open(player.gameDesign.Url, '_blank');
  };
  const handlePurchase = () => {
    dispatch(showPurchaseSearch());
    navigate('/purchases');
  };
  const handlePicture = () => {
    dispatch(setPicture(true));
    navigate('/picture');
  };
  useEffect(() => {
    if (player.gameDesign == null) {
      dispatch(fetchGameDesign());
    }
  });
  return (
    <>
      {' '}
      <div>
        <IconButton
          edge="start"
          onClick={handleToggle}
          className="main-menu-button"
          color="inherit"
          aria-label="menu">
          <MenuIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={state.anchorEl}
          keepMounted
          open={state.menuOpen}
          onClose={handleClose}>
          <MenuItem
            onClick={() => {
              handleLogOut();
            }}>
            <ListItemIcon>
              <ExitToApp fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Exit" />
          </MenuItem>
          <MenuItem onClick={handleEditUser}>
            <ListItemIcon>
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Change Password" />
          </MenuItem>
          <MenuItem
            onClick={() => {
              handlePurchase();
            }}>
            <ListItemIcon>
              <MonetizationOnIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Purchase Records" />
          </MenuItem>
          {
            <MenuItem onClick={handleNewUser}>
              <ListItemIcon>
                <PersonAddIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Create Account" />
            </MenuItem>
          }
          {
            <MenuItem onClick={handleVersionList}>
              <ListItemIcon>
                <DnsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Version Config" />
            </MenuItem>
          }
          {
            <MenuItem onClick={handlePicture}>
              <ListItemIcon>
                <PhotoSizeSelectActualIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Picture" />
            </MenuItem>
          }
          {
            <MenuItem onClick={handleRestart}>
              <ListItemIcon>
                <CachedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Restart" />
            </MenuItem>
          }
          <MenuItem onClick={getAuthToken}>
            <ListItemIcon>
              <VpnKeyIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Get Auth Token" />
          </MenuItem>
          <MenuItem onClick={handleOpenBalance}>
            <ListItemIcon>
              <OpenInNewIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={player.gameDesign != null && player.gameDesign.Name} />
          </MenuItem>
        </Menu>
        <Dialog
          open={user.authToken !== ''}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{'Paste this to unity'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <code style={{ overflowWrap: 'break-word' }}>{user.authToken}</code>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={copyAuthToken} endIcon={<FileCopyIcon />}>
              Copy
            </Button>
            <Button onClick={hideAuthToken} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

// class MainMenu extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { menuOpen: false, anchorEl: null };
//   }
//   handleToggle = (e) => {
//     this.setState({ ...this.state, anchorEl: e.currentTarget, menuOpen: !this.state.menuOpen });
//   };
//   handleNewUser = () => {
//     this.setState({ ...this.state, anchorEl: null, menuOpen: false });
//     this.props.selectUser({ Name: '', Login: '', Pwd: '' });
//   };
//   handleEditUser = () => {
//     this.setState({ ...this.state, anchorEl: null, menuOpen: false });
//     this.props.startEditUser();
//   };
//   handleLogOut = () => {
//     setCookie('auth', 'logout');
//     window.location.reload();
//   };
//   handleClose = () => {
//     this.setState({ ...this.state, anchorEl: null, menuOpen: false });
//   };
//   // вот тут нужно зазобраться
//   handleVersionList = () => {
//     this.setState({ ...this.state, anchorEl: null, menuOpen: false });
//     this.props.fetchVersionList();
//   };
//   handleRestart = () => {
//     this.props.restart();
//     this.setState({ ...this.state, menuOpen: false });
//   };
//   getAuthToken = () => {
//     this.handleClose();
//     this.props.requestAuthToken();
//   };
//   copyAuthToken = () => {
//     navigator.clipboard.writeText(this.props.authToken);
//   };
//   hideAuthToken = () => {
//     console.log('hiding');
//     this.props.showAuthToken('');
//   };
//   handleOpenBalance = () => {
//     // console.log(this.props)
//     window.open(this.props.gd.Url, '_blank');
//   };
//   handlePurchase = () => {
//     this.props.showPurchaseSearch();
//   };
//   handlePicture = () => {
//     this.props.setPicture(true);
//   };
//   componentDidMount = () => {
//     if (this.props.gd == null) {
//       this.props.fetchGameDesign();
//     }
//   };
//   render() {
//     return (
//       <div>
//         <IconButton
//           edge="start"
//           onClick={this.handleToggle}
//           className="main-menu-button"
//           color="inherit"
//           aria-label="menu">
//           <MenuIcon />
//         </IconButton>
//         <Menu
//           id="simple-menu"
//           anchorEl={this.state.anchorEl}
//           keepMounted
//           open={this.state.menuOpen}
//           onClose={this.handleClose}>
//           <MenuItem
//             onClick={() => {
//               this.props.navigate('/');
//               this.handleLogOut();
//             }}>
//             <ListItemIcon>
//               <ExitToApp fontSize="small" />
//             </ListItemIcon>
//             <ListItemText primary="Exit" />
//           </MenuItem>
//           <MenuItem onClick={this.handleEditUser}>
//             <ListItemIcon>
//               <PersonIcon fontSize="small" />
//             </ListItemIcon>
//             <ListItemText primary="Change Password" />
//           </MenuItem>
//           <MenuItem onClick={this.handlePurchase}>
//             <ListItemIcon>
//               <MonetizationOnIcon fontSize="small" />
//             </ListItemIcon>
//             <ListItemText primary="Purchase Records" />
//           </MenuItem>
//           {
//             <MenuItem onClick={this.handleNewUser}>
//               <ListItemIcon>
//                 <PersonAddIcon fontSize="small" />
//               </ListItemIcon>
//               <ListItemText primary="Create Account" />
//             </MenuItem>
//           }
//           {
//             <MenuItem onClick={this.handleVersionList}>
//               <ListItemIcon>
//                 <DnsIcon fontSize="small" />
//               </ListItemIcon>
//               <ListItemText primary="Version Config" />
//             </MenuItem>
//           }
//           {
//             <MenuItem onClick={this.handlePicture}>
//               <ListItemIcon>
//                 <PhotoSizeSelectActualIcon fontSize="small" />
//               </ListItemIcon>
//               <ListItemText primary="Picture" />
//             </MenuItem>
//           }
//           {
//             <MenuItem onClick={this.handleRestart}>
//               <ListItemIcon>
//                 <CachedIcon fontSize="small" />
//               </ListItemIcon>
//               <ListItemText primary="Restart" />
//             </MenuItem>
//           }
//           <MenuItem onClick={this.getAuthToken}>
//             <ListItemIcon>
//               <VpnKeyIcon fontSize="small" />
//             </ListItemIcon>
//             <ListItemText primary="Get Auth Token" />
//           </MenuItem>
//           <MenuItem onClick={this.handleOpenBalance}>
//             <ListItemIcon>
//               <OpenInNewIcon fontSize="small" />
//             </ListItemIcon>
//             <ListItemText primary={this.props.gd != null && this.props.gd.Name} />
//           </MenuItem>
//         </Menu>

//         <Dialog
//           open={this.props.authToken !== ''}
//           aria-labelledby="alert-dialog-title"
//           aria-describedby="alert-dialog-description">
//           <DialogTitle id="alert-dialog-title">{'Paste this to unity'}</DialogTitle>
//           <DialogContent>
//             <DialogContentText id="alert-dialog-description">
//               <code style={{ overflowWrap: 'break-word' }}>{this.props.authToken}</code>
//             </DialogContentText>
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={this.copyAuthToken} endIcon={<FileCopyIcon />}>
//               Copy
//             </Button>
//             <Button onClick={this.hideAuthToken} autoFocus>
//               Close
//             </Button>
//           </DialogActions>
//         </Dialog>
//       </div>
//     );
//   }
// }

const mapStateToProps = (state) => {
  return { user: state.user.current, gd: state.player.gameDesign, authToken: state.user.authToken };
};

export default connect(mapStateToProps, {
  startEditUser,
  fetchVersionList,
  selectUser,
  requestAuthToken,
  showAuthToken,
  fetchGameDesign,
  restart,
  showPurchaseSearch,
  setPicture,
})(MainMenu);
