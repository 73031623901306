import {
  AppBar,
  Button,
  Container,
  Fab,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import React from 'react';
import { setPicture } from '../redux/versionsActions';
import { useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { AddPicture } from './AddPicture';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import {
  setOpen,
  fetchPictureLink,
  fetchPictureArrName,
  fetchDownloadPicture,
  fetchDeletePicture,
} from '../redux/pictureSlice';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useJwt } from 'react-jwt';
import { useRef } from 'react';
import { getDateRefresh } from '../other/time';

export const Picture = () => {
  const navigate = useNavigate();
  const url = useSelector((state) => state.pictureSlice.url);
  // const makeRequest = useSelector((state) => state.pictureSlice.makeRequest);
  const loader = useSelector((state) => state.pictureSlice.loader);
  const pictureArrName = useSelector((state) => state.pictureSlice.pictureArrName);
  const dispatch = useDispatch();
  const [cookies, _, removeCookie] = useCookies(['auth']);
  const { decodedToken } = useJwt(cookies.auth);
  const intervalRef = useRef();
  //This is a temporary fix that needs to be changed.
  useEffect(() => {
    if (
      typeof getDateRefresh(decodedToken?.exp) === 'number' &&
      getDateRefresh(decodedToken?.exp) !== 0 &&
      !isNaN(getDateRefresh(decodedToken?.exp))
    ) {
      intervalRef.current = setInterval(() => {
        removeCookie('auth');
        navigate('/');
        alert('you have run out of session time, go back to the application to continue');
      }, getDateRefresh(decodedToken?.exp) - 5000);
    }
    return () => clearInterval(intervalRef.current);
  }, [decodedToken, navigate, removeCookie, _]);
  //
  useEffect(() => {
    dispatch(fetchPictureLink());
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchPictureArrName());
  }, [dispatch]);

  return (
    <div>
      <AppBar position="sticky">
        {loader === true ? <CircularProgress className="picture__loader"></CircularProgress> : null}
        <Toolbar>
          <IconButton
            aria-label="back"
            color="inherit"
            onClick={() => {
              dispatch(setPicture(null));
              navigate('/app');
            }}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" className="flex-grow">
            Picture
          </Typography>
          {/* <Button
            color="inherit"
            startIcon={<RefreshIcon />}
            onClick={() => {
              dispatch(fetchPictureArrName());
            }}>
            Refresh
          </Button> */}
        </Toolbar>
      </AppBar>
      <Container maxWidth="md" style={{ marginTop: '20px' }}>
        <Grid container direction="row" alignItems="center" spacing={2}>
          {pictureArrName?.map((e, i) => {
            console.log(e);
            if (e === undefined) {
              return <></>;
            }
            return (
              <Grid item xs key={i}>
                <Card sx={{ maxWidth: '500px' }}>
                  <CardMedia
                    component="img"
                    alt="green iguana"
                    height="250"
                    image={`/api/image?configName=${e}`}
                  />
                  <CardContent></CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      onClick={() => {
                        console.log(url);
                        navigator.clipboard.writeText(`${url}${e}`);
                      }}>
                      Copy Link
                    </Button>
                    <Button
                      size="small"
                      onClick={() => {
                        console.log(`/api/image?configName=${e}`);
                        // downloadImage(`api/image?configName=${e}`);
                        dispatch(fetchDownloadPicture(`api/image?configName=${e}`));
                      }}>
                      DOWNLOAD
                    </Button>
                    <Button
                      size="small"
                      onClick={() => {
                        // deletePicture(e);
                        dispatch(fetchDeletePicture(e));
                      }}>
                      delete
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Container>
      <Fab
        color="primary"
        className="add-device-button"
        aria-label="add"
        onClick={() => dispatch(setOpen(true))}>
        <AddIcon />
      </Fab>
      <AddPicture />
      {/* <VersionEditDialog /> */}
    </div>
  );
};
