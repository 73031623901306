import {
  RECEIVE_GAME_DESIGN,
  RECEIVE_PLAYER,
  SELECT_PLAYER,
  SET_PLAYER_DEVICES,
  SET_PLAYER_GIFTS,
  SET_PLAYER_LTL_STATE,
  SET_PLAYER_OFFER_HISTORY,
} from './actionTypes';
import { setUserOpResult } from './userActions';
import { apiFetch } from './actions';
import JSONbig from 'json-bigint';

export const selectPlayer = (player) => ({
  type: SELECT_PLAYER,
  payload: { player },
});

export const receiveGameDesign = (gameDesign) => ({
  type: RECEIVE_GAME_DESIGN,
  payload: { gameDesign },
});

export const receivePlayer = (player) => ({
  type: RECEIVE_PLAYER,
  payload: { player },
});
export const setPlayerGifts = (playerId, gifts) => ({
  type: SET_PLAYER_GIFTS,
  payload: { playerId, gifts },
});
export const setPlayerOfferHistory = (offerHistory) => ({
  type: SET_PLAYER_OFFER_HISTORY,
  payload: { offerHistory },
});
export const setPlayerLtlState = (ltlState) => ({
  type: SET_PLAYER_LTL_STATE,
  payload: { ltlState },
});
export const setPlayerDevices = (devices) => ({
  type: SET_PLAYER_DEVICES,
  payload: { devices },
});
export function fetchPlayer(playerId, deviceId) {
  return function (dispatch) {
    dispatch(selectPlayer({ user_id: playerId }));
    let param = `userId=` + playerId;
    if (!playerId) {
      param = `deviceId=` + deviceId;
    }
    return apiFetch(
      `/api/user?` + param,
      {},
      dispatch,
      'Looking For Player',
      (json) => {
        dispatch(receivePlayer(json));
      },
      () => {
        dispatch(receivePlayer(null));
      },
    );
  };
}
export function addMaterial(playerId, materialId, count) {
  return function (dispatch) {
    dispatch(makeAction(playerId, 'addMaterial', { material: materialId, count: count }));
  };
}
export function setMap(playerId, mapName) {
  return function (dispatch) {
    dispatch(makeAction(playerId, 'setMap', { MapName: mapName }));
  };
}
export function resetMap(playerId, mapName) {
  return function (dispatch) {
    dispatch(makeAction(playerId, 'resetMap', { MapName: mapName }));
  };
}
export function addUnlocks(playerId, unlocks) {
  return function (dispatch) {
    dispatch(makeAction(playerId, 'addUnlock', { Unlocks: unlocks }));
  };
}
export function addXp(playerId, xp) {
  return function (dispatch) {
    dispatch(makeAction(playerId, 'addXp', { Xp: xp }));
  };
}
export function enableDebug(playerId) {
  return function (dispatch) {
    dispatch(makeAction(playerId, 'enableDebug', { Xp: 'enable' }));
  };
}
export function resetPlayer(playerId) {
  return function (dispatch) {
    dispatch(makeAction(playerId, 'aNull', { reset: 'reset' }));
  };
}

export function makeAction(playerId, action, param) {
  return function (dispatch) {
    console.log(param);
    return apiFetch(
      `/api/user?userId=` + playerId + `&action=` + action,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSONbig.stringify(param),
      },
      dispatch,
      'Editing Player',
      (json) => {
        dispatch(receivePlayer(json));
      },
      () => {
        dispatch(receivePlayer(null));
      },
    );
  };
}
export function fetchGameDesign() {
  return function (dispatch) {
    return fetch(`/api/gameDesign`)
      .then((response) => response.json())
      .then(
        (json) => {
          dispatch(receiveGameDesign(json));
        },
        (error) => {
          dispatch(setUserOpResult(String(error)));
        },
      );
  };
}
export function fetchPlayerGifts(playerId) {
  return function (dispatch) {
    let param = `userId=` + playerId;
    return apiFetch(
      `/api/userGifts?` + param,
      {},
      dispatch,
      "Getting player's gifts",
      (json) => {
        dispatch(setPlayerGifts(playerId, json));
      },
      () => {},
    );
  };
}
export function fetchPlayerOfferHistory(playerId) {
  return function (dispatch) {
    let param = `userId=` + playerId;
    return apiFetch(
      `/api/offerHistory?` + param,
      {},
      dispatch,
      'Getting offer history',
      (bigJson) => {
        let json = JSONbig.stringify(bigJson);
        json = JSON.parse(json);
        dispatch(setPlayerOfferHistory(json));
      },
      () => {},
    );
  };
}

export function updatePlayerOfferHistory(playerId, offerHistory) {
  return function (dispatch) {
    let param = `userId=` + playerId;
    delete offerHistory.user_id;
    return apiFetch(
      `/api/offerHistory?` + param,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(offerHistory),
      },
      dispatch,
      'Updating offer history',
      (json) => {
        dispatch(setPlayerOfferHistory(json));
      },
      () => {},
    );
  };
}
export function fetchPlayerDevices(playerId) {
  return function (dispatch) {
    let param = `userId=` + playerId;
    return apiFetch(
      `/api/playerDevices?` + param,
      {},
      dispatch,
      'Getting devices',
      (bigJson) => {
        let json = JSONbig.stringify(bigJson);
        json = JSON.parse(json);
        dispatch(setPlayerDevices(json));
      },
      () => {},
    );
  };
}

export function fetchPlayerLtlState(playerId) {
  return function (dispatch) {
    let param = `userId=` + playerId;
    return apiFetch(
      `/api/ltlState?` + param,
      {},
      dispatch,
      'Getting ltl state',
      (bigJson) => {
        let json = JSONbig.stringify(bigJson);
        json = JSON.parse(json);
        dispatch(setPlayerLtlState(json));
      },
      () => {},
    );
  };
}

export function updatePlayerLtlState(playerId, ltlState) {
  return function (dispatch) {
    let param = `userId=` + playerId;
    delete ltlState.user_id;
    return apiFetch(
      `/api/ltlState?` + param,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(ltlState),
      },
      dispatch,
      'Updating ltl state',
      (json) => {
        dispatch(setPlayerLtlState(json));
      },
      () => {},
    );
  };
}
export function addPlayerGift(playerId, gift) {
  return function (dispatch) {
    let param = `userId=` + playerId;
    return apiFetch(
      `/api/userGifts?` + param,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSONbig.stringify(gift),
      },
      dispatch,
      'Adding Gift',
      (json) => {
        dispatch(setPlayerGifts(playerId, json));
      },
      () => {},
    );
  };
}
export function deletePlayerGift(playerId, giftId) {
  return function (dispatch) {
    let param = `userId=` + playerId + `&reward_id=` + giftId;
    return apiFetch(
      `/api/userGifts?` + param,
      {
        method: 'DELETE',
      },
      dispatch,
      'Deleting Gift',
      (json) => {
        dispatch(setPlayerGifts(playerId, json));
      },
      () => {},
    );
  };
}
