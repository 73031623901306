import { combineReducers } from 'redux';
import devices from './devices';
import user from './user';
import player from './player';
import asyncOperation from './asyncOperation';
import versions from './versions';
import purchaseRecord from './purchaseRecord';
import registration from './registration';
import open from './open';
import pictureSlice from '../pictureSlice';
export default combineReducers({
  devices,
  user,
  player,
  asyncOperation,
  versions,
  purchaseRecord,
  registration,
  open,
  pictureSlice,
});
