import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { deleteVersion, startVersionEdit } from '../redux/versionsActions';
import { useDispatch } from 'react-redux';
const VersionInfo = (props) => {
  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch(deleteVersion(props.versionName));
    console.log('delete');
  };
  const handleEdit = () => {
    dispatch(startVersionEdit(props.versionName));
    console.log('edit');
  };
  return (
    <>
      <TableRow>
        <TableCell className="absorbing-column">{props.versionName}</TableCell>
        <TableCell>
          <Button variant="contained" color="primary" onClick={handleEdit} startIcon={<EditIcon />}>
            Edit
          </Button>
        </TableCell>
        <TableCell>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDelete}
            startIcon={<DeleteIcon />}>
            Delete
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};
// class VersionInfo extends React.Component {
//   handleDelete = () => {
//     this.props.deleteVersion(this.props.versionName);
//     console.log('delete');
//   };
//   handleEdit = () => {
//     this.props.startVersionEdit(this.props.versionName);
//     console.log('edit');
//   };

//   render() {
//     return (
//       <TableRow>
//         <TableCell
//           onClick={() => {
//             console.log(this);
//           }}
//           className="absorbing-column">
//           {this.props.versionName}
//         </TableCell>

//         <TableCell>
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={this.handleEdit}
//             startIcon={<EditIcon />}>
//             Edit
//           </Button>
//         </TableCell>
//         <TableCell>
//           <Button
//             variant="contained"
//             color="secondary"
//             onClick={this.handleDelete}
//             startIcon={<DeleteIcon />}>
//             Delete
//           </Button>
//         </TableCell>
//       </TableRow>
//     );
//   }
// }
// export default connect(null, { startVersionEdit, deleteVersion })(VersionInfo);
export default VersionInfo;
