import { SELECT_USER, SET_CURRENT_USER, SET_USER_OP_RESULT } from './actionTypes';

export const selectUser = (user) => ({
  type: SELECT_USER,
  payload: { user },
});

export const setCurrentUser = (user) => ({
  type: SET_CURRENT_USER,
  payload: user,
});

export const setUserOpResult = (result) => ({
  type: SET_USER_OP_RESULT,
  payload: { result },
});

export function startEditUser() {
  return function (dispatch) {
    return fetch(`/api/adminUser`)
      .then((response) => response.json())
      .then((json) => dispatch(selectUser(json)));
  };
}

export function restart() {
  return function () {
    return fetch(`/api/restart`)
      .then((_) => new Promise((resolve) => setTimeout(resolve, 3000)))
      .then((_) => {
        // debugger;
        window.location.reload(false);
      });
  };
}

export function fetchCurrentUser() {
  return function (dispatch) {
    return fetch(`/api/adminUser`)
      .then((response) => response.json())
      .then((json) => dispatch(setCurrentUser(json)));
  };
}

export function updateUser(userName, userLogin, userPwd, accessLevel) {
  return function (dispatch) {
    dispatch(selectUser(null));
    return fetch(`/api/adminUser`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Name: userName,
        Login: userLogin,
        Pwd: userPwd,
        AccessLevel: accessLevel,
      }),
    })
      .then((response) => response.json())
      .then(
        (_json) => dispatch(setUserOpResult('User edited')),
        (error) => {
          dispatch(setUserOpResult(String(error)));
        },
      );
  };
}
