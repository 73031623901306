import { SELECT_USER, SET_USER_OP_RESULT, SET_CURRENT_USER, SHOW_AUTH_TOKEN } from '../actionTypes';

const initialState = {
  selected: null,
  opResult: null,
  current: null,
  authToken: '',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SELECT_USER: {
      //const { id } = action.payload;
      return {
        ...state,
        selected: action.payload.user,
      };
    }
    case SET_USER_OP_RESULT:
      return {
        ...state,
        opResult: action.payload.result,
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        current: action.payload,
      };
    case SHOW_AUTH_TOKEN:
      return {
        ...state,
        authToken: action.payload,
      };
    default:
      return state;
  }
}
