import {
  OPEN_UPDATE_DEVICE_DIALOGUE,
  CLOSE_UPDATE_DEVICE_DIALOGUE,
  SET_DEVICE_LIST_LOADING,
  RECEIVE_DEVICE_LIST,
  DEVICE_OP_COMPLETED,
  SELECT_DEVICE,
  SHOW_AUTH_TOKEN,
  REGISTER_OPEN,
  OPEN_MODAL_PICTURE,
} from './actionTypes';
import { startAsyncOp, opResult } from './asyncOperationActions';
import { v4 as uuidv4 } from 'uuid';
import JSONbig from 'json-bigint';

export const openUpdateDeviceDialogue = () => ({
  type: OPEN_UPDATE_DEVICE_DIALOGUE,
  payload: {},
});

export const closeUpdateDeviceDialogue = () => ({
  type: CLOSE_UPDATE_DEVICE_DIALOGUE,
  payload: {},
});

export const setDeviceListLoading = () => ({
  type: SET_DEVICE_LIST_LOADING,
  payload: {},
});

export const receiveDeviceList = (json, deviceType) => ({
  type: RECEIVE_DEVICE_LIST,
  payload: { json, deviceType },
});

export const deviceOpCompleted = () => ({
  type: DEVICE_OP_COMPLETED,
  payload: {},
});

export const selectDevice = (deviceInfo) => ({
  type: SELECT_DEVICE,
  payload: { deviceInfo },
});
export const showAuthToken = (token) => ({
  type: SHOW_AUTH_TOKEN,
  payload: token,
});
export const register = (open) => ({
  type: REGISTER_OPEN,
  payload: open,
});
export const openModal = (open) => ({
  type: OPEN_MODAL_PICTURE,
  payload: open,
});
export function requestAuthToken() {
  return function (dispatch) {
    return fetch(`/api/token`, {
      method: 'GET',
    })
      .then((response) => response.text())
      .then((token) => dispatch(showAuthToken(token)));
  };
}
export function addDevice(deviceName, deviceId) {
  return function (dispatch) {
    dispatch(closeUpdateDeviceDialogue());
    dispatch(setDeviceListLoading());
    return fetch(`/api/device`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSONbig.stringify({ Name: deviceName, DeviceId: deviceId }),
    })
      .then((response) => response.json())
      .then((_json) => dispatch(fetchDeviceList()));
  };
}
export function deleteDevice(deviceInfoId, creator) {
  return function (dispatch) {
    dispatch(setDeviceListLoading());

    return fetch(`/api/device`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSONbig.stringify({ Id: deviceInfoId, Creator: creator }),
    })
      .then((response) => response.text())
      .then((_json) => dispatch(fetchDeviceList()));
  };
}
export function fetchDeviceList(deviceType) {
  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.

  return function (dispatch) {
    // First dispatch: the app state is updated to inform
    // that the API call is starting.

    dispatch(setDeviceListLoading());

    // The function called by the thunk middleware can return a value,
    // that is passed on as the return value of the dispatch method.

    // In this case, we return a promise to wait for.
    // This is not required by thunk middleware, but it is convenient for us.

    return fetch(`/api/deviceList?deviceType=` + deviceType)
      .then(
        (response) => response.text(),
        // Do not use catch, because errors occured during rendering
        // should be handled by React Error Boundaries
        // https://reactjs.org/docs/error-boundaries.html
      )
      .then((json) => {
        // We can dispatch many times!
        // Here, we update the app state with the results of the API call.
        try {
          let bj = JSONbig({ storeAsString: true }).parse(json);
          dispatch(receiveDeviceList(bj, deviceType));
        } catch (e) {
          setTimeout(() => {
            // debugger;
            window.location.reload();
          }, 2000);
        }
      });
  };
}

export function fetchHandler(res) {
  if (res.status >= 400 && res.status < 600) {
    return res.text().then((text) => Promise.reject(text));
  }
  return res.text();
}
export function fetchTextHandler(res) {
  if (res.status >= 400 && res.status < 600) {
    return res.text().then((text) => Promise.reject(text));
  }
  return res.text();
}

export function apiFetch(url, param, dispatch, description, cb, errCb, text) {
  const opId = uuidv4();
  let op = { opId, description, pending: true };
  dispatch(startAsyncOp(op));
  return fetch(url, param)
    .then(fetchTextHandler, (error) => {
      dispatch(opResult(opId, description, false, String(error)));
      errCb();
    })
    .then(
      (json) => {
        let parsed = !text ? JSONbig({ storeAsString: true }).parse(json) : json;
        dispatch(opResult(opId, description, true));
        cb(parsed);
      },
      (error) => {
        dispatch(opResult(opId, description, false, String(error)));
        if (errCb) errCb();
      },
    );
}
//export const setFilter = filter => ({ type: SET_FILTER, payload: { filter } });
