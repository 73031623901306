import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import React, { useState } from 'react';
import {
  saveVersion,
  setSelectedVersion,
  updateLocalSelectedVersion,
} from '../redux/versionsActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import TextField from '@material-ui/core/TextField';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { useDispatch, useSelector } from 'react-redux';

const VersionEditDialog = () => {
  const dispatch = useDispatch();
  const [newJson, setNewJson] = useState(null);
  const [newName, setNewName] = useState('');
  const versions = useSelector((state) => state.versions);
  const handleClose = () => {
    dispatch(setSelectedVersion(null));
    // this.props.setSelectedVersion(null);
  };
  const handleSave = () => {
    if (newJson == null) return;
    let name = versions?.selected?.Name;
    if (name === '') {
      name = newName;
    }
    if (name === '') return;
    dispatch(saveVersion(name, newJson));
  };
  const handleChange = (v) => {
    setNewJson(v);
  };
  const handleNewNameChange = (e) => {
    setNewName(e.target.value);
  };
  const handleAddSimpleVersion = () => {
    let s = versions?.selected?.json;
    s = {
      ...s,
      url: '',
      askForReset: false,
      privacyVersion: 1,
      onlyEu: false,
      saveVersion: '10',
    };
    dispatch(
      updateLocalSelectedVersion(versions?.selected?.Name, s, versions?.selected?.localVersion + 1),
    );
  };
  const handleAddHardUpdate = () => {
    let s = versions?.selected?.json;
    s = {
      ...s,
      message: 'New version is avaliable!\nPlease update!',
      messageUrl: 'https://play.google.com/store/apps/details?id=com.theguligames.landsofadventure',
      messageIos: 'New version is available. Please Update',
      messageUrlIos: 'https://apps.apple.com/by/app/adventure-lands-family-farm/id1546055730',
    };
    dispatch(
      updateLocalSelectedVersion(versions?.selected?.Name, s, versions?.selected?.localVersion + 1),
    );
  };

  const handleAddPrivacy = () => {
    let s = versions?.selected?.json;
    s = {
      ...s,
      message: 'New version is avaliable!\nPlease update!',
      messageUrl: 'https://play.google.com/store/apps/details?id=com.theguligames.landsofadventure',
    };
    dispatch(
      updateLocalSelectedVersion(versions?.selected?.Name, s, versions?.selected?.localVersion + 1),
    );
  };
  if (versions?.selected == null) {
    return <div />;
  }
  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        {' '}
        {versions?.selected?.Name !== '' ? versions?.selected?.Name : 'New Config'}
      </DialogTitle>
      {versions?.selected?.Name === '' && (
        <DialogContent>
          <TextField
            type="text"
            fullWidth
            value={newName}
            onChange={handleNewNameChange}
            label="Name"
          />
        </DialogContent>
      )}

      <DialogContent>
        <ButtonGroup color="primary" aria-label="outlined primary button group">
          <Button onClick={handleAddSimpleVersion}>Add Simple Version</Button>
          <Button onClick={handleAddHardUpdate}>Add Force Update</Button>
          <Button onClick={handleAddPrivacy}>Add Privacy</Button>
        </ButtonGroup>
      </DialogContent>
      <DialogContent>
        <Editor
          key={versions?.selected?.localVersion}
          allowedModes={['code', 'tree']}
          value={versions?.selected?.json}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          disabled={
            (versions?.selected?.Name === '' && newName.length < 5) ||
            newJson === undefined ||
            newJson == null ||
            Object.keys(newJson).length === 0
          }
          color="primary"
          autoFocus>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
// const mapStateToProps = (state) => {
//   return { version: state.versions.selected };
// };

// export default connect(mapStateToProps, {
//   setVersionList,
//   fetchVersionList,
//   setSelectedVersion,
//   updateLocalSelectedVersion,
//   saveVersion,
// })(VersionEditDialog);

export default VersionEditDialog;
