import {
  RECEIVE_DEVICE_LIST,
  OPEN_UPDATE_DEVICE_DIALOGUE,
  CLOSE_UPDATE_DEVICE_DIALOGUE,
  SET_DEVICE_LIST_LOADING,
  SELECT_DEVICE,
} from '../actionTypes';

const initialState = {
  selected: null,
  listValid: false,
  updateDialogueOpen: false,
  devices: [],
  deviceType: 'my',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SELECT_DEVICE:
      return {
        ...state,
        selected: action.payload.deviceInfo,
      };
    case SET_DEVICE_LIST_LOADING:
      console.log('load devices');
      return {
        ...state,
        listValid: false,
      };
    case RECEIVE_DEVICE_LIST:
      console.log('received devices', action);
      return {
        ...state,
        listValid: true,
        devices: action.payload.json,
        deviceType: action.payload.deviceType,
      };
    case OPEN_UPDATE_DEVICE_DIALOGUE:
      return {
        ...state,
        updateDialogueOpen: true,
      };
    case CLOSE_UPDATE_DEVICE_DIALOGUE:
      return {
        ...state,
        updateDialogueOpen: false,
      };
    default:
      return state;
  }
}
