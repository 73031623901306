import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './redux/store';
import { CookiesProvider } from 'react-cookie';
import { RegistrationPage } from './components/RegistrationPage';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import VersionList from './components/VersionList';
import { Picture } from './components/Picture';
import ErrorPage from './components/ErrorPage';
import PurchasesRoot from './components/PurchasesRoot';
// import ReactDOM from 'react-dom/client';
import OperationDisplay from './components/OperationDisplay';
// import reportWebVitals from './reportWebVitals';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RegistrationPage />,
  },
  {
    path: 'app',
    element: <App />,
  },
  {
    path: 'version',
    element: <VersionList />,
  },
  {
    path: 'picture',
    element: <Picture />,
  },
  {
    path: 'purchases',
    element: <PurchasesRoot />,
  },
  {
    path: '*',
    element: <ErrorPage />,
  },
]);

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <Provider store={store}>
//     {/* <React.StrictMode> */}
//     <CookiesProvider>
//       <RouterProvider router={router} />
//       <OperationDisplay />
//     </CookiesProvider>
//     {/* </React.StrictMode> */}
//   </Provider>,
// );

// reportWebVitals();
ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <CookiesProvider>
        <RouterProvider router={router} />
        <OperationDisplay />
      </CookiesProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root'),
);
serviceWorker.unregister();
