import {
  RECEIVE_PLAYER,
  SELECT_PLAYER,
  SET_PLAYER_GIFTS,
  RECEIVE_GAME_DESIGN,
  SET_PLAYER_OFFER_HISTORY,
  SET_PLAYER_LTL_STATE,
  SET_PLAYER_DEVICES,
} from '../actionTypes';

const initialState = {
  selected: null,
  gameDesign: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SELECT_PLAYER: {
      //const { id } = action.payload;
      return {
        ...state,
        selected: action.payload.player,
      };
    }
    case SET_PLAYER_OFFER_HISTORY:
      return {
        ...state,
        selected: { ...state.selected, offerHistory: action.payload.offerHistory },
      };
    case SET_PLAYER_LTL_STATE:
      return {
        ...state,
        selected: { ...state.selected, ltlState: action.payload.ltlState },
      };
    case SET_PLAYER_DEVICES:
      return {
        ...state,
        selected: { ...state.selected, devices: action.payload.devices },
      };
    case RECEIVE_PLAYER:
      return {
        ...state,
        selected: action.payload.player,
      };
    case RECEIVE_GAME_DESIGN:
      return {
        ...state,
        gameDesign: action.payload.gameDesign,
      };
    case SET_PLAYER_GIFTS:
      if (state.selected == null || state.selected.user_id !== action.payload.playerId) {
        return state;
      }
      return {
        ...state,
        selected: { ...state.selected, gifts: action.payload.gifts },
      };
    default:
      return state;
  }
}
