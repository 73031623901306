import FileSaver from 'file-saver';
import { apiFetch } from './actions';

export function fetchDump(format, deviceId, userId) {
  return function (dispatch) {
    let param;
    if (deviceId) {
      param = 'deviceId=' + deviceId;
    } else {
      param = 'userId=' + userId;
    }

    param += '&format=' + format;
    let bType = 'text/plain;charset=utf-8';
    if (format !== 'json') {
      bType = 'application/octet-stream';
    }
    return apiFetch(
      `/api/dump?` + param,
      {},
      dispatch,
      'Creating Save',
      (text) => {
        let d = atob(text);
        let l = d.length;
        let array = new Uint8Array(l);
        for (let i = 0; i < l; i++) {
          array[i] = d.charCodeAt(i);
        }
        let blob = new Blob([array], { type: bType });
        FileSaver.saveAs(blob, 'dump.' + format);
      },
      (_e) => {},
      true,
    );
  };
}
export function uploadDump(deviceId, data, isJson) {
  let header = 'application/json';
  if (!isJson) {
    header = 'application/octet-stream';
  }
  return function (dispatch) {
    return apiFetch(
      `/api/dump?deviceId=` + deviceId,
      {
        method: 'PUT',
        headers: {
          'Content-Type': header,
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: data,
      },
      dispatch,
      'Uploading Save',
      (json) => {
        console.log(json);
      },
      (_e) => {},
    );
  };
}

export function linkDeviceAndUser(opDescription, deviceId, userId) {
  let userIdParam = '';
  if (userId) {
    userIdParam = '&userId=' + userId;
  }
  return function (dispatch) {
    return apiFetch(
      `/api/userDevice?deviceId=` + deviceId + userIdParam,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      },
      dispatch,
      opDescription,
      (json) => {
        console.log(json);
      },
      (_e) => {},
    );
  };
}
