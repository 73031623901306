import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {
  clearPurchaseSearch,
  //  setPurchaseSearch,
  setPurchaseList,
} from '../redux/purchaseRecord';
import { Search } from '@material-ui/icons';
import PurchaseList from './PurchaseList';
import PurchaseSearch from './PurchaseSearch';
// import { useNavigate } from 'react-router-dom';
import store from '../redux/store';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { useRef } from 'react';
import { useJwt } from 'react-jwt';
import { getDateRefresh } from '../other/time';
const PurchasesRoot = () => {
  // const [state, setState] = useState({ updateDialogueOpen: false, searchValue: '' });
  const dispatch = useDispatch();
  const result = useSelector((state) => state.purchaseRecord.purchases?.result);
  const navigate = useNavigate();
  const [cookies, _, removeCookie] = useCookies(['auth']);
  const { decodedToken } = useJwt(cookies.auth);
  const intervalRef = useRef();
  //This is a temporary fix that needs to be changed.
  useEffect(() => {
    if (
      typeof getDateRefresh(decodedToken?.exp) === 'number' &&
      getDateRefresh(decodedToken?.exp) !== 0 &&
      !isNaN(getDateRefresh(decodedToken?.exp))
    ) {
      intervalRef.current = setInterval(() => {
        removeCookie('auth');
        navigate('/');
        alert('you have run out of session time, go back to the application to continue');
      }, getDateRefresh(decodedToken?.exp) - 5000);
    }
    return () => clearInterval(intervalRef.current);
  }, [decodedToken, navigate, removeCookie, _]);
  //
  const handleBack = () => {
    dispatch(clearPurchaseSearch());
  };
  const handleNewSearch = () => {
    dispatch(setPurchaseList(null));
  };
  useEffect(() => {
    console.log(store?.getState()?.purchaseRecord?.purchases?.result);
  }, [result]);

  return (
    <div>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            aria-label="back"
            color="inherit"
            onClick={() => {
              handleBack();
              navigate('/app');
            }}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" className="flex-grow">
            Purchases
          </Typography>
          <Button color="inherit" onClick={handleNewSearch} startIcon={<Search />}>
            Search
          </Button>
        </Toolbar>
      </AppBar>
      {result != null ? <PurchaseList /> : <PurchaseSearch />}
    </div>
  );
};
export default PurchasesRoot;
// class PurchasesRoot extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { updateDialogueOpen: false, searchValue: '' };
//   }
//   handleBack = () => {
//     this.props.clearPurchaseSearch();
//   };

//   handleNewSearch = () => {
//     this.props.setPurchaseList(null);
//   };
//   render() {
//     return (
//       <div>
//         <AppBar position="sticky">
//           <Toolbar>
//             <IconButton aria-label="back" color="inherit" onClick={this.handleBack}>
//               <ArrowBackIosIcon />
//             </IconButton>
//             <Typography variant="h6" className="flex-grow">
//               Purchases
//             </Typography>
//             <Button color="inherit" onClick={this.handleNewSearch} startIcon={<Search />}>
//               Search
//             </Button>
//           </Toolbar>
//         </AppBar>
//         {this?.props?.purchases?.result != null ? <PurchaseList /> : <PurchaseSearch />}
//       </div>
//     );
//   }
// }

// const mapStateToProps = (state) => {
//   return state.purchaseRecord;
// };

// export default connect(mapStateToProps, {
//   setPurchaseSearch,
//   clearPurchaseSearch,
//   setPurchaseList,
// })(PurchasesRoot);
