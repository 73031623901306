import React from 'react';
import { connect } from 'react-redux';
import { fetchDeviceList, openUpdateDeviceDialogue, selectDevice } from '../redux/actions';
import { selectUser, startEditUser } from '../redux/userActions';
import { fetchPlayer } from '../redux/playerActions';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import DeviceInfo from './DeviceInfo';
import AddDevice from './AddDevice';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeviceActions from './DeviceActions';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import UserSearch from './UserSearch';
import Icon from '@material-ui/core/Icon';
import PlayerInfo from './PlayerInfo';
import MainMenu from './MainMenu';
// import VersionList from './VersionList';
// import PurchasesRoot from './PurchasesRoot';
import Grid from '@material-ui/core/Grid';
import { Send } from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import { CompletionTime } from './CompletionTime';
// import { Picture } from './Picture';
import { setPicture } from '../redux/versionsActions';
// import VersionList from './VersionList';
// import { PurchasesRoot } from './PurchasesRoot';

class DeviceList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { updateDialogueOpen: false, searchValue: '', directDeviceId: '' };
  }
  componentDidMount() {
    if (!this.props.listValid) {
      this.props.fetchDeviceList('my');
      console.log(this.state.picture);
    }
  }
  handleUpdateSearch = (event) => {
    this.setState({ ...this.state, searchValue: event.target.value });
  };
  isLiked = (deviceId) => {
    return localStorage.getItem('Liked_' + deviceId) === 'True';
  };
  handleRefresh = () => {
    this.props.fetchDeviceList(this.props.deviceType);
  };
  handleMyDevices = () => {
    this.props.fetchDeviceList('my');
  };
  handleAllDevices = () => {
    this.props.fetchDeviceList('all');
  };
  handleAddDevice = () => {
    this.props.openUpdateDeviceDialogue();
  };
  handleSearchUser = () => {
    this.props.fetchPlayerById(this.state.searchValue);
  };
  handleDirectDeviceId = (event) => {
    this.setState({ ...this.state, directDeviceId: event.target.value });
  };
  handleDirectDeviceIdEdit = () => {
    this.props.selectDevice({ Name: 'Device', DeviceId: this.state.directDeviceId });
  };
  render() {
    console.log(this.props);
    if (!this.props.listValid) {
      return (
        <div className="center-loading">
          <CircularProgress />
          <Typography>Loading..</Typography>
        </div>
      );
      // вот тут исправить
    }
    // else if (this.props.purchases != null) {
    //   return <PurchasesRoot />;
    // }
    else if (this.props.selectedPlayer != null) {
      return <PlayerInfo />;
    } else if (this.props.selected != null) {
      return <DeviceActions />;
    }
    // else if (this.props.versions != null) {
    //   return <VersionList />;
    // }
    // else if (this.props.picture != null) {
    //   return <Picture />;
    // }
    else {
      return (
        <div>
          <AppBar position="sticky" className={'device-list-app-bar'}>
            <Toolbar>
              <MainMenu navigate={this.props.navigate} />
              <Hidden mdDown>
                <Typography variant="h6" className="flex-grow">
                  Device List
                </Typography>
                <UserSearch searchChange={this.handleUpdateSearch} />
                <Button
                  color="inherit"
                  onClick={this.handleSearchUser}
                  startIcon={<Icon>send</Icon>}>
                  {' '}
                  Find User
                </Button>
              </Hidden>
              {/* <Button color="inherit" onClick={this.handleRefresh} startIcon={<RefreshIcon />}>
                Refresh
              </Button> */}
            </Toolbar>
          </AppBar>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Button
              variant={this.props.deviceType === 'my' ? 'outlined' : 'contained'}
              onClick={this.handleMyDevices}
              color="primary"
              style={{ margin: '5px' }}>
              My Devices
            </Button>
            <Button
              variant={this.props.deviceType === 'all' ? 'outlined' : 'contained'}
              onClick={this.handleAllDevices}
              color="primary"
              style={{ margin: '5px' }}>
              All Devices
            </Button>
            <Hidden mdDown>
              <TextField
                style={{ margin: '5px' }}
                id="outlined-basic"
                value={this.state.directDeviceId}
                label="Device Id"
                variant="outlined"
                onChange={this.handleDirectDeviceId}
              />
              <Button
                variant={'contained'}
                endIcon={<Send />}
                onClick={this.handleDirectDeviceIdEdit}
                color="primary"
                style={{ margin: '5px' }}>
                Direct Edit
              </Button>
            </Hidden>
          </Grid>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableBody>
                {this?.props?.devices
                  ?.sort((a, b) => {
                    let bLike = this?.isLiked(b.DeviceId);
                    let aLike = this?.isLiked(a.DeviceId);
                    if (bLike && !aLike) {
                      return 1;
                    }
                    if (!bLike && aLike) {
                      return -1;
                    }
                    return a.Name.localeCompare(b.Name);
                  })
                  .map((row) => (
                    <DeviceInfo key={row.Id} deviceInfo={row} liked={this.isLiked(row.DeviceId)} />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Fab
            color="primary"
            className="add-device-button"
            aria-label="add"
            onClick={this.handleAddDevice}>
            <AddIcon />
          </Fab>
          <AddDevice />
          <CompletionTime />
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.devices,
    selectedPlayer: state.player.selected,
    deviceType: state.devices.deviceType,
    versions: state.versions.versionList,
    purchases: state.purchaseRecord.purchases,
    picture: state.versions.picture,
  };
};

export default connect(mapStateToProps, {
  fetchDeviceList,
  selectDevice,
  openUpdateDeviceDialogue,
  selectUser,
  startEditUser,
  fetchPlayerById: fetchPlayer,
  setPicture: setPicture,
})(DeviceList);

// function that check var
