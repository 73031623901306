import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { connect } from 'react-redux';
import { deleteDevice, selectDevice } from '../redux/actions';
import { fetchPlayer } from '../redux/playerActions';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import Hidden from '@material-ui/core/Hidden';
import FaceIcon from '@material-ui/icons/Face';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import FavoriteIcon from '@material-ui/icons/Favorite';
class DeviceInfo extends React.Component {
  constructor(props) {
    super(props);
    this.deviceInfo = props.deviceInfo;
  }

  handleDelete = () => {
    this.props.deleteDevice(this.deviceInfo.Id, this.deviceInfo.Creator);
  };
  handleOpenActions = () => {
    this.props.selectDevice(this.deviceInfo);
  };
  handleOpenPlayer = () => {
    this.props.fetchPlayer(null, this.deviceInfo.DeviceId);
  };

  render() {
    return (
      <TableRow>
        <TableCell>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
            {this.props.liked && <FavoriteIcon color="primary" style={{ paddingRight: '10px' }} />}
            {this.deviceInfo.Name}
          </div>
        </TableCell>
        <Hidden mdDown>
          <TableCell>{this.deviceInfo.DeviceId}</TableCell>
        </Hidden>
        <TableCell>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleOpenActions}
            startIcon={<PhonelinkSetupIcon />}>
            <Hidden mdDown> Device</Hidden>
          </Button>
        </TableCell>
        <TableCell>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleOpenPlayer}
            startIcon={<FaceIcon />}>
            <Hidden mdDown> Player</Hidden>
          </Button>
        </TableCell>
        <Hidden mdDown>
          <TableCell>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.handleDelete}
              startIcon={<DeleteIcon />}>
              {' '}
              Delete
            </Button>
          </TableCell>
        </Hidden>
      </TableRow>
    );
  }
}

export default connect(null, { deleteDevice, selectDevice, fetchPlayer })(DeviceInfo);
