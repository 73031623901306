import React, { useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import FaceIcon from '@material-ui/icons/Face';
import { fetchPlayer } from '../redux/playerActions';
import { clearPurchaseSearch } from '../redux/purchaseRecord';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const PurchaseInfo = (props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const findUser = () => {
    dispatch(fetchPlayer(props.purchase.user_id));
    dispatch(clearPurchaseSearch());
  };

  // const reformatDate = (d) => {
  //   let pd = new Date(d);
  //   return (
  //     pd.getDay() +
  //     '/' +
  //     (pd.getMonth() + 1) +
  //     '/' +
  //     pd.getFullYear() +
  //     ' ' +
  //     pd.getHours() +
  //     ':' +
  //     pd.getMinutes() +
  //     ':' +
  //     pd.getSeconds()
  //   );
  // };
  const openPayload = () => {
    let wnd = window.open('about:blank', '', '_blank');
    wnd.document.write('<pre>' + JSON.stringify(props?.purchase, null, 2) + '</pre>');
  };
  return (
    <>
      <React.Fragment>
        <TableRow>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen((e) => !e)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>
            {props?.purchase?.verified ? (
              <CheckCircleIcon color={'primary'} />
            ) : (
              <ErrorIcon color={'error'} />
            )}
          </TableCell>
          <TableCell>
            {props?.purchase?.consumed ? (
              <CheckCircleIcon color={'primary'} />
            ) : (
              <ErrorIcon color={'error'} />
            )}{' '}
          </TableCell>
          <TableCell>
            {props?.purchase?.delivered ? (
              <CheckCircleIcon color={'primary'} />
            ) : (
              <ErrorIcon color={'error'} />
            )}{' '}
          </TableCell>
          <TableCell>{props?.purchase?.test ? 'Test' : 'Real'}</TableCell>
          <TableCell>
            {props?.purchase?.creation_time
              ? new Date(props.purchase.creation_time.seconds * 1000).toLocaleString()
              : null}
          </TableCell>{' '}
          <TableCell>
            {props?.purchase?.order_id}
            <br />
            <span style={{ color: 'gray' }}>{props?.purchase?.transaction_id}</span>{' '}
          </TableCell>
          <TableCell>
            {' '}
            User:{props?.purchase?.user_id}
            <br />
            <span style={{ color: 'gray' }}>Device:{props?.purchase?.device_id}</span>{' '}
          </TableCell>
          <TableCell>
            <Link to={'/app'}>
              <Button
                variant="contained"
                color="primary"
                onClick={findUser}
                startIcon={<FaceIcon />}>
                Player
              </Button>
            </Link>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant={'body1'}>
                  TransactionId: {props?.purchase?.transaction_id}
                </Typography>
                <div color="red">
                  <pre>{JSON.stringify(props?.purchase?.error, null, 2)}</pre>
                </div>
                {props?.purchase?.reward && (
                  <div>
                    <pre>{JSON.stringify(props?.purchase?.reward, null, 2)}</pre>
                  </div>
                )}

                <Button variant="contained" color="primary" onClick={openPayload}>
                  JSON
                </Button>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    </>
  );
};
export default PurchaseInfo;

// class PurchaseInfo extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { open: false };
//   }
//   findUser = () => {
//     this.props.fetchPlayer(this.props.purchase.user_id);
//     this.props.clearPurchaseSearch();
//   };

//   reformatDate = (d) => {
//     let pd = new Date(d);
//     return (
//       pd.getDay() +
//       '/' +
//       (pd.getMonth() + 1) +
//       '/' +
//       pd.getFullYear() +
//       ' ' +
//       pd.getHours() +
//       ':' +
//       pd.getMinutes() +
//       ':' +
//       pd.getSeconds()
//     );
//   };
//   setOpen = (open) => {
//     this.setState({ open: open });
//   };
//   openPayload = () => {
//     let wnd = window.open('about:blank', '', '_blank');
//     wnd.document.write('<pre>' + JSON.stringify(this.props.purchase, null, 2) + '</pre>');
//   };
//   render() {
//     return (
//       <React.Fragment>
//         <TableRow>
//           <TableCell>
//             <IconButton
//               aria-label="expand row"
//               size="small"
//               onClick={() => this.setOpen(!this.state.open)}>
//               {this.state.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
//             </IconButton>
//           </TableCell>
//           <TableCell>
//             {this.props.purchase.verified ? (
//               <CheckCircleIcon color={'primary'} />
//             ) : (
//               <ErrorIcon color={'error'} />
//             )}
//           </TableCell>
//           <TableCell>
//             {this.props.purchase.consumed ? (
//               <CheckCircleIcon color={'primary'} />
//             ) : (
//               <ErrorIcon color={'error'} />
//             )}{' '}
//           </TableCell>
//           <TableCell>
//             {this.props.purchase.delivered ? (
//               <CheckCircleIcon color={'primary'} />
//             ) : (
//               <ErrorIcon color={'error'} />
//             )}{' '}
//           </TableCell>

//           <TableCell>{this.props.purchase.test ? 'Test' : 'Real'}</TableCell>
//           <TableCell>{this.props.purchase.time}</TableCell>
//           <TableCell>
//             {this.props.purchase.order_id}
//             <br />
//             <span style={{ color: 'gray' }}>{this.props.purchase.transaction_id}</span>{' '}
//           </TableCell>
//           <TableCell>
//             {' '}
//             User:{this.props.purchase.user_id}
//             <br />
//             <span style={{ color: 'gray' }}>Device:{this.props.purchase.device_id}</span>{' '}
//           </TableCell>
//           <TableCell>
//             <Button
//               variant="contained"
//               color="primary"
//               onClick={this.findUser}
//               startIcon={<FaceIcon />}>
//               Player
//             </Button>
//           </TableCell>
//         </TableRow>
//         <TableRow>
//           <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
//             <Collapse in={this.state.open} timeout="auto" unmountOnExit>
//               <Box margin={1}>
//                 <Typography variant={'body1'}>
//                   TransactionId: {this.props.purchase.transaction_id}
//                 </Typography>
//                 <div color="red">
//                   <pre>{JSON.stringify(this.props.purchase.error, null, 2)}</pre>
//                 </div>
//                 {this.props.purchase.reward && (
//                   <div>
//                     <pre>{JSON.stringify(this.props.purchase.reward, null, 2)}</pre>
//                   </div>
//                 )}

//                 <Button variant="contained" color="primary" onClick={this.openPayload}>
//                   JSON
//                 </Button>
//               </Box>
//             </Collapse>
//           </TableCell>
//         </TableRow>
//       </React.Fragment>
//     );
//   }
// }

// export default connect(null, { fetchPlayer, clearPurchaseSearch })(PurchaseInfo);
