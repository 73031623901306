import React from 'react';
import { useCookies } from 'react-cookie';
import { useJwt } from 'react-jwt';
import { getDate } from '../other/time';

export const CompletionTime = () => {
  const [cookies] = useCookies(['auth']);
  const { decodedToken } = useJwt(cookies.auth);

  return (
    <div className="pumping-time">
      <div>Completion time</div>
      <div>{getDate(decodedToken)}</div>
    </div>
  );
};
