import React, { useEffect, useState } from 'react'; // useState
import {
  connect,
  //  , useDispatch, useSelector
} from 'react-redux';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import AddDevice from './AddDevice';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { fetchVersionList, newVersion, setVersionList } from '../redux/versionsActions';
import VersionInfo from './VersionInfo';
import VersionEditDialog from './VersionEditDialog';
// import store from '../redux/store';
import { useDispatch } from 'react-redux';
import {
  Link,
  useNavigate,
  //  useNavigate
} from 'react-router-dom';
import store from '../redux/store';
import { useCookies } from 'react-cookie';
import { useRef } from 'react';
import { getDateRefresh } from '../other/time';
import { useJwt } from 'react-jwt';

const VersionList = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [cookies, _, removeCookie] = useCookies(['auth']);
  const navigate = useNavigate();
  const { decodedToken } = useJwt(cookies.auth);
  const intervalRef = useRef();
  //This is a temporary fix that needs to be changed.
  useEffect(() => {
    if (
      typeof getDateRefresh(decodedToken?.exp) === 'number' &&
      getDateRefresh(decodedToken?.exp) !== 0 &&
      !isNaN(getDateRefresh(decodedToken?.exp))
    ) {
      intervalRef.current = setInterval(() => {
        removeCookie('auth');
        navigate('/');
        alert('you have run out of session time, go back to the application to continue');
      }, getDateRefresh(decodedToken?.exp) - 5000);
    }
    return () => clearInterval(intervalRef.current);
  }, [decodedToken, navigate, removeCookie, _]);
  //

  const handleBack = () => {
    dispatch(setVersionList(null));
  };
  // const handleRefresh = () => {
  //   dispatch(fetchVersionList());
  // };
  const handleAddVersion = () => {
    setOpen(true);
    dispatch(newVersion());
  };
  useEffect(() => {
    dispatch(fetchVersionList());
  }, [dispatch]);
  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          <Link to={'/app'} style={{ color: '#fff' }}>
            <IconButton aria-label="back" color="inherit" onClick={handleBack}>
              <ArrowBackIosIcon />
            </IconButton>
          </Link>
          <Typography variant="h6" className="flex-grow">
            Versions
          </Typography>
          {/* <Button color="inherit" onClick={handleRefresh} startIcon={<RefreshIcon />}>
            Refresh
          </Button> */}
        </Toolbar>
      </AppBar>
      <TableContainer component={Paper} className="versions-table">
        <Table aria-label="simple table">
          <TableBody>
            {store
              .getState()
              .versions?.versionList?.sort((a, b) => b.localeCompare(a))
              .map((versionName) => (
                <VersionInfo key={versionName} versionName={versionName} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Fab
        color="primary"
        className="add-device-button"
        aria-label="add"
        onClick={handleAddVersion}>
        <AddIcon />
      </Fab>
      <AddDevice />
      <VersionEditDialog open={open} setOpen={setOpen} />
      {/* <OperationDisplay /> */}
    </>
  );
};
// class VersionList extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { updateDialogueOpen: false, searchValue: '' };
//   }
//   handleBack = () => {
//     this.props.setVersionList(null);
//   };
//   handleRefresh = () => {
//     this.props.fetchVersionList();
//   };

//   handleAddVersion = () => {
//     this.props.newVersion();
//   };

//   render() {
//     return (
//       <div>
//         <AppBar position="sticky">
//           <Toolbar>
//             <Link to={'/app'} style={{ color: '#fff' }}>
//               <IconButton aria-label="back" color="inherit" onClick={this.handleBack}>
//                 <ArrowBackIosIcon />
//               </IconButton>
//             </Link>
//             <Typography variant="h6" className="flex-grow">
//               Versions
//             </Typography>
//             <Button color="inherit" onClick={this.handleRefresh} startIcon={<RefreshIcon />}>
//               Refresh
//             </Button>
//           </Toolbar>
//         </AppBar>
//         <TableContainer component={Paper} className="versions-table">
//           <Table aria-label="simple table">
//             <TableBody>
//               {this.props?.versions
//                 ?.sort((a, b) => b.localeCompare(a))
//                 .map((versionName) => (
//                   <VersionInfo key={versionName} versionName={versionName} />
//                 ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//         <Fab
//           color="primary"
//           className="add-device-button"
//           aria-label="add"
//           onClick={this.handleAddVersion}>
//           <AddIcon />
//         </Fab>
//         <AddDevice />
//         <VersionEditDialog />
//         <OperationDisplay />
//       </div>
//     );
//   }
// }

const mapStateToProps = (state) => {
  return {
    user: state.user.current,
    versions: state.versions.versionList,
    selectedVersion: state.versions.selected,
  };
};

export default connect(mapStateToProps, { setVersionList, fetchVersionList, newVersion })(
  VersionList,
);
