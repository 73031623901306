import React from 'react';
import { connect } from 'react-redux';
import { searchRecords } from '../redux/purchaseRecord';
import 'jsoneditor-react/es/editor.min.css';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { Search } from '@material-ui/icons';

// const PurchaseSearch = () => {
//   const [userId, setUserId] = useState('');
//   const [deviceId, setDeviceId] = useState('');
//   const [transactionId, setTransactionId] = useState('');
//   const [orderId, setOrderId] = useState('');
//   const [verified, setVerified] = useState('no-one');
//   const handleUserIdChange = (event) => {
//     setUserId(event.target.value);
//   };
//   const handleDeviceIdChange = (event) => {
//     setDeviceId(event.target.value);
//   };
//   const handleTransactionIdChange = (event) => {
//     setTransactionId(event.target.value);
//   };
//   const handleOrderIdChange = (event) => {
//     setOrderId(event.target.value);
//   };
//   const updateState = (newState) => {
//     let count = 0;
//     if (userId && userId !== '') {
//       count++;
//     }
//     if (deviceId && deviceId !== '') {
//       count++;
//     }
//     if (transactionId && transactionId !== '') {
//       count++;
//     }
//     if (orderId && orderId !== '') {
//       count++;
//     }
//     if (count === 0) {
//       setVerified('no-one');
//     } else if (count > 1) {
//       setVerified('more-then-one');
//     } else {
//       setVerified('ok');
//     }
//     // this.setState(newState);
//   };
//   const findPurchase = () => {
//     store.dispatch(searchRecords(userId, deviceId, transactionId, orderId));
//     // this.props.searchRecords(
//     //   this.state.userId,
//     //   this.state.deviceId,
//     //   this.state.transactionId,
//     //   this.state.orderId,
//     // );
//   };
//   return (
//     <>
//       <Container maxWidth="sm">
//         <Paper>
//           <Container maxWidth="sm">
//             <form noValidate autoComplete="off">
//               <div>
//                 <TextField
//                   id="userId"
//                   label="User Id"
//                   value={userId}
//                   onChange={handleUserIdChange}
//                   fullWidth
//                   margin="normal"
//                 />
//               </div>
//               <div>
//                 <TextField
//                   id="deviceId"
//                   label="Device Id"
//                   value={deviceId}
//                   onChange={handleDeviceIdChange}
//                   fullWidth
//                   margin="normal"
//                 />
//               </div>
//               <div>
//                 <TextField
//                   id="transactionId"
//                   label="Transaction Id"
//                   value={transactionId}
//                   onChange={handleTransactionIdChange}
//                   fullWidth
//                   margin="normal"
//                 />
//               </div>
//               <div>
//                 <TextField
//                   id="orderId"
//                   label="Order Id"
//                   value={orderId}
//                   onChange={handleOrderIdChange}
//                   fullWidth
//                   margin="normal"
//                 />
//               </div>
//               <div>
//                 {verified === 'no-one' && <Alert severity="warning">Fill one field</Alert>}
//                 {verified === 'more-then-one' && (
//                   <Alert severity="warning">Fill only one field!</Alert>
//                 )}
//                 <FormControl margin="normal">
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     disabled={verified !== 'ok'}
//                     onClick={findPurchase}
//                     startIcon={<Search />}>
//                     Search
//                   </Button>
//                 </FormControl>
//               </div>
//               <br />
//             </form>
//           </Container>
//         </Paper>
//       </Container>
//     </>
//   );
// };
// export default PurchaseSearch;
class PurchaseSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = { userId: '', deviceId: '', transactionId: '', orderId: '', verified: 'no-one' };
  }
  handleUserIdChange = (event) => {
    this.updateState({ ...this.state, userId: event.target.value });
  };
  handleDeviceIdChange = (event) => {
    this.updateState({ ...this.state, deviceId: event.target.value });
  };
  handleTransactionIdChange = (event) => {
    this.updateState({ ...this.state, transactionId: event.target.value });
  };
  handleOrderIdChange = (event) => {
    this.updateState({ ...this.state, orderId: event.target.value });
  };
  updateState = (newState) => {
    let count = 0;
    if (newState.userId && newState.userId !== '') {
      count++;
    }
    if (newState.deviceId && newState.deviceId !== '') {
      count++;
    }
    if (newState.transactionId && newState.transactionId !== '') {
      count++;
    }
    if (newState.orderId && newState.orderId !== '') {
      count++;
    }
    if (count === 0) {
      newState.verified = 'no-one';
    } else if (count > 1) {
      newState.verified = 'more-then-one';
    } else {
      newState.verified = 'ok';
    }
    console.log(newState);
    this.setState(newState);
  };
  findPurchase = () => {
    this.props.searchRecords(
      this.state.userId,
      this.state.deviceId,
      this.state.transactionId,
      this.state.orderId,
    );
  };
  render() {
    return (
      <Container maxWidth="sm">
        <Paper>
          <Container maxWidth="sm">
            <form noValidate autoComplete="off">
              <div>
                <TextField
                  id="userId"
                  label="User Id"
                  value={this.state.userId}
                  onChange={this.handleUserIdChange}
                  fullWidth
                  margin="normal"
                />
              </div>
              <div>
                <TextField
                  id="deviceId"
                  label="Device Id"
                  value={this.state.deviceId}
                  onChange={this.handleDeviceIdChange}
                  fullWidth
                  margin="normal"
                />
              </div>
              <div>
                <TextField
                  id="transactionId"
                  label="Transaction Id"
                  value={this.state.transactionId}
                  onChange={this.handleTransactionIdChange}
                  fullWidth
                  margin="normal"
                />
              </div>
              <div>
                <TextField
                  id="orderId"
                  label="Order Id"
                  value={this.state.orderId}
                  onChange={this.handleOrderIdChange}
                  fullWidth
                  margin="normal"
                />
              </div>
              <div>
                {this.state.verified === 'no-one' && (
                  <Alert severity="warning">Fill one field</Alert>
                )}
                {this.state.verified === 'more-then-one' && (
                  <Alert severity="warning">Fill only one field!</Alert>
                )}
                <FormControl margin="normal">
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={this.state.verified !== 'ok'}
                    onClick={this.findPurchase}
                    startIcon={<Search />}>
                    Search
                  </Button>
                </FormControl>
              </div>

              <br />
            </form>
          </Container>
        </Paper>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return { version: state.versions.selected };
};

export default connect(mapStateToProps, { searchRecords })(PurchaseSearch);
