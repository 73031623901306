import React from 'react';
import { connect } from 'react-redux';
import { closeUpdateDeviceDialogue, addDevice } from '../redux/actions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';

class AddDevice extends React.Component {
  constructor(props) {
    super(props);
    this.state = { deviceName: '', deviceId: '' };
  }
  handleAddDevice = () => {
    this.props.addDevice(this.state.deviceName, this.state.deviceId);
  };
  handleNameChange = (event) => {
    this.setState({ ...this.state, deviceName: event.target.value });
  };
  handleDeviceIdChange = (event) => {
    this.setState({ ...this.state, deviceId: event.target.value });
  };
  handleClose = () => {
    this.props.closeUpdateDeviceDialogue();
  };
  render() {
    return (
      <Dialog
        open={this.props.updateDialogueOpen}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title">
        <DialogContent>
          <DialogContentText>
            Locate device's UUID on login screen or settings menu
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Device Name"
            type="text"
            value={this.state.deviceName}
            onChange={this.handleNameChange}
            fullWidth
          />
          <TextField
            margin="dense"
            id="deviceId"
            label="Device ID"
            type="text"
            value={this.state.deviceId}
            onChange={this.handleDeviceIdChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={(e) => {
              if (this.state.deviceName.length < 10) {
                return console.log(false);
              }
              if (this.state.deviceId.length < 10) {
                return console.log(false);
              }
              this.handleAddDevice();
            }}
            color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return { ...state.devices };
};

export default connect(mapStateToProps, { closeUpdateDeviceDialogue, addDevice })(AddDevice);
