export const OPEN_UPDATE_DEVICE_DIALOGUE = 'OPEN_UPDATE_DEVICE_DIALOGUE';
export const CLOSE_UPDATE_DEVICE_DIALOGUE = 'CLOSE_UPDATE_DEVICE_DIALOGUE';

export const SET_DEVICE_LIST_LOADING = 'SET_DEVICE_LIST_LOADING';
export const RECEIVE_DEVICE_LIST = 'RECEIVE_DEVICE_LIST';
export const DEVICE_OP_COMPLETED = 'DEVICE_OP_COMPLETED';
export const SELECT_DEVICE = 'SELECT_DEVICE';

export const SELECT_USER = 'SELECT_USER';
export const SET_USER_OP_RESULT = 'SET_USER_OP_RESULT';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const SELECT_PLAYER = 'SELECT_PLAYER';
export const RECEIVE_PLAYER = 'RECEIVE_PLAYER';
export const RECEIVE_GAME_DESIGN = 'RECEIVE_GAME_DESIGN';

export const START_ASYNC_OP = 'START_ASYNC_OP';
export const DISPLAY_OP_RESULT = 'DISPLAY_OP_RESULT';
export const DISPLAY_OP_RESULT_COMPLETE = 'DISPLAY_OP_RESULT_COMPLETE';

export const SET_PLAYER_GIFTS = 'SET_PLAYER_GIFTS';
export const SET_PLAYER_OFFER_HISTORY = 'SET_PLAYER_OFFER_HISTORY';
export const SET_PLAYER_LTL_STATE = 'SET_PLAYER_LTL_STATE';
export const SET_PLAYER_DEVICES = 'SET_PLAYER_DEVICES';

export const SET_VERSION_LIST = 'SET_VERSION_LIST';
export const SET_SELECTED_VERSION = 'SET_SELECTED_VERSION';
export const SET_SHOW_VERSION_LIST = 'SET_SHOW_VERSION_LIST';

export const SET_PURCHASE_SEARCH = 'SET_PURCHASE_SEARCH';
export const SET_PURCHASE_LIST = 'SET_PURCHASE_LIST';
export const CLEAR_PURCHASE_SEARCH = 'CLEAR_PURCHASE_SEARCH';
export const SHOW_PURCHASE_SEARCH = 'SHOW_PURCHASE_SEARCH';

export const SHOW_AUTH_TOKEN = 'SHOW_AUTH_TOKEN';
export const REGISTER_OPEN = 'REGISTER_OPEN';
export const PICTURE = 'PICTURE';
export const OPEN_MODAL_PICTURE = 'OPEN_MODAL_PICTURE';
export const SET_PICTURE = 'SET_PICTURE';
export const SET_PICTURE_LIST = 'SET_PICTURE_LIST';
