import React from 'react';
// import { connect } from 'react-redux';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import PurchaseInfo from './PurchaseInfo';
// import store from '../redux/store';
import { useSelector } from 'react-redux';

const PurchaseList = () => {
  const result = useSelector((state) => state.purchaseRecord.purchases.result);

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>verified</TableCell>
              <TableCell>Consumed</TableCell>
              <TableCell>Delivered</TableCell>
              <TableCell>Test</TableCell>
              <TableCell>When & What</TableCell>
              <TableCell>Order Id</TableCell>
              <TableCell>Attribution</TableCell>
              <TableCell>GoTo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {result?.map((purchase) => (
              <PurchaseInfo
                key={purchase.transaction_id + '-' + purchase.user_id}
                purchase={purchase}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
// write function that sum string

// class PurchaseList extends React.Component {
//   render() {
//     return (
//       <TableContainer component={Paper}>
//         <Table aria-label="simple table">
//           <TableHead>
//             <TableRow>
//               <TableCell />
//               <TableCell>verified</TableCell>
//               <TableCell>Consumed</TableCell>
//               <TableCell>Delivered</TableCell>
//               <TableCell>Test</TableCell>
//               <TableCell>When & What</TableCell>
//               <TableCell>Order Id</TableCell>
//               <TableCell>Attribution</TableCell>
//               <TableCell>GoTo</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {this.props.result.map((purchase) => (
//               <PurchaseInfo
//                 key={purchase.transaction_id + '-' + purchase.user_id}
//                 purchase={purchase}
//               />
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     );
//   }
// }

// const mapStateToProps = (state) => {
//   return { result: state.purchaseRecord.purchases.result };
// };

export default PurchaseList;
