import {
  CLEAR_PURCHASE_SEARCH,
  SHOW_PURCHASE_SEARCH,
  SET_PURCHASE_LIST,
  SET_PURCHASE_SEARCH,
} from '../actionTypes';

const initialState = {
  purchases: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PURCHASE_LIST: {
      //const { id } = action.payload;
      return {
        purchases: {
          ...state.purchases,
          result: action.payload,
        },
      };
    }
    case SET_PURCHASE_SEARCH:
      return {
        purchases: {
          ...state.purchases,
          search: action.payload,
        },
      };
    case CLEAR_PURCHASE_SEARCH:
      return {
        purchases: null,
      };
    case SHOW_PURCHASE_SEARCH:
      return {
        purchases: { search: {}, result: null },
      };
    default:
      return state;
  }
}
