import { START_ASYNC_OP, DISPLAY_OP_RESULT, DISPLAY_OP_RESULT_COMPLETE } from './actionTypes';

export const startAsyncOp = (op) => ({
  type: START_ASYNC_OP,
  payload: op,
});

export const displayOpResult = (op) => ({
  type: DISPLAY_OP_RESULT,
  payload: op,
});

export const displayOpResultComplete = () => ({
  type: DISPLAY_OP_RESULT_COMPLETE,
  payload: {},
});

export function opResult(opId, description, result, resultMessage) {
  return function (dispatch) {
    dispatch(displayOpResult({ opId, result, description, resultMessage, pending: false }));
  };
}
