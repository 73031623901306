import {
  CLEAR_PURCHASE_SEARCH,
  SET_PURCHASE_LIST,
  SET_PURCHASE_SEARCH,
  SHOW_PURCHASE_SEARCH,
} from './actionTypes';
import { apiFetch } from './actions';

export const clearPurchaseSearch = () => ({
  type: CLEAR_PURCHASE_SEARCH,
});
export const setPurchaseList = (payload) => ({
  type: SET_PURCHASE_LIST,
  payload,
});
export const showPurchaseSearch = () => ({
  type: SHOW_PURCHASE_SEARCH,
});
export const setPurchaseSearch = (playerId, deviceId, transactionId, orderId) => ({
  type: SET_PURCHASE_SEARCH,
  payload: { playerId, deviceId, transactionId, orderId },
});

export function searchRecords(playerId, deviceId, transactionId, orderId) {
  return function (dispatch) {
    let param = `userId=` + playerId;
    if (deviceId && deviceId !== '') {
      param = `deviceId=` + deviceId;
    }
    if (transactionId && transactionId !== '') {
      param = `transactionId=` + transactionId;
    }
    if (orderId && orderId !== '') {
      param = `orderId=` + orderId;
    }

    return apiFetch(
      `/api/purchaseRecordList?` + param,
      {},
      dispatch,
      'Fetching records',
      (json) => {
        dispatch(setPurchaseList(json));
      },
    );
  };
}
