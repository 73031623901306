import React from 'react';
import SearchIcon from '@material-ui/icons/Search';

const styles = {
  search: {
    position: 'relative',
    borderRadius: '4px',
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.25)',
    },
    marginLeft: 0,
    marginRight: 50,
  },
  searchIcon: {
    padding: '0 8px',
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: '8px 8px 8px 0',
    paddingLeft: 'calc(1em + 32px)',
    transition: 'width 300ms ease-in-out',
    width: '100%',
  },
};

export default function UserSearch(props) {
  return (
    <div style={styles.search}>
      <div style={styles.searchIcon}>
        <SearchIcon />
      </div>
      <input
        placeholder="User ID"
        style={styles.inputInput}
        onChange={props.searchChange}
        aria-label="search"
      />
    </div>
  );
}
