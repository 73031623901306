import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

export default function Error() {
  const [cookies, setCookie] = useCookies(['auth']);
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#3f50b5',
      }}>
      <Typography variant="h1" style={{ color: 'white' }}>
        404
      </Typography>

      <Button
        variant="contained"
        onClick={() => {
          console.log(cookies);
          navigate('/');
          setCookie('auth', null);
        }}>
        Back Home
      </Button>
    </Box>
  );
}
