import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import { connect } from 'react-redux';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import { Cancel, Check } from '@material-ui/icons';

class JsonEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { newJson: null };
  }
  handleClose = () => {
    this.props.onClose();
  };
  handleSave = () => {
    if (this.state.newJson == null) return;
    this.props.onSave(this.state.newJson);
  };
  handleChange = (v) => {
    this.setState({ ...this.state, newJson: v });
  };
  handleRefresh = () => {
    //let uid = this.props.user_id
    //this.props.setPlayerOfferHistory(null);
    this.props.onRefresh();
  };
  render() {
    console.log('Render:', this.state);
    console.log('PR:', this.props.toEdit);
    //return <div />
    if (this.props.toEdit == null) {
      return <div />;
    }

    return (
      <Dialog
        open={true}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={'md'}>
        <DialogTitle id="alert-dialog-title">Json Editor</DialogTitle>

        <DialogContent>
          <Editor
            key={this.props.user_id}
            allowedModes={['code', 'tree']}
            value={this.props.toEdit}
            onChange={this.handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={this.handleClose}
            color="primary"
            endIcon={<Cancel />}>
            Cancel
          </Button>
          {/* <Button
            variant="contained"
            onClick={this.handleRefresh}
            color="primary"
            endIcon={<Refresh />}>
            Refresh
          </Button> */}
          <Button
            variant="contained"
            onClick={this.handleSave}
            disabled={
              this.state.newJson === undefined ||
              this.state.newJson == null ||
              Object.keys(this.state.newJson).length === 0
            }
            color="primary"
            autoFocus
            endIcon={<Check />}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {})(JsonEditDialog);
