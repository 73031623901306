import {
  PICTURE,
  SET_PICTURE_LIST,
  SET_SELECTED_VERSION,
  SET_SHOW_VERSION_LIST,
  SET_VERSION_LIST,
} from './actionTypes';
import { apiFetch } from './actions';
import JSONbig from 'json-bigint';

export const setSelectedVersion = (versionJson) => ({
  type: SET_SELECTED_VERSION,
  payload: versionJson,
});

export const setVersionList = (versionList) => ({
  type: SET_VERSION_LIST,
  payload: versionList,
});
export const setPictureList = (pictureList) => ({
  type: SET_PICTURE_LIST,
  payload: pictureList,
});
export const setShowVersionList = (versionList) => ({
  type: SET_SHOW_VERSION_LIST,
  payload: versionList,
});
export const setPicture = (picture) => ({
  type: PICTURE,
  payload: picture,
});
export function fetchVersionList() {
  return function (dispatch) {
    return apiFetch(
      `/api/versionList`,
      {},
      dispatch,
      'Getting Versions',
      (json) => {
        dispatch(setVersionList(json));
      },
      () => {},
    );
  };
}
export function fetchVersionPicture() {
  return function (dispatch) {
    return apiFetch(
      `/api/picture`,
      {},
      dispatch,
      'Getting Versions',
      (json) => {
        dispatch(setPictureList(json));
      },
      () => {},
    );
  };
}
export const newVersion = () => ({
  type: SET_SELECTED_VERSION,
  payload: { json: {}, Name: '', localVersion: 1 },
});
export const updateLocalSelectedVersion = (name, json, lv) => ({
  type: SET_SELECTED_VERSION,
  payload: { json: json, Name: name, localVersion: lv },
});
export function startVersionEdit(versionName) {
  return function (dispatch) {
    return apiFetch(
      `/api/version?configName=` + versionName,
      {},
      dispatch,
      'Getting ' + versionName,
      (bigJson) => {
        let tempJson = JSONbig.stringify(bigJson);
        let json = JSON.parse(tempJson);
        console.log('TempJson:', tempJson, json);
        dispatch(setSelectedVersion({ json, Name: versionName, localVersion: 1 }));
      },
      () => {},
    );
  };
}

export function saveVersion(versionName, data) {
  return function (dispatch) {
    return apiFetch(
      `/api/version?configName=` + versionName,
      {
        method: 'PUT',
        body: JSON.stringify(data),
      },
      dispatch,
      'Saving: ' + versionName,
      (_json) => {
        dispatch(setSelectedVersion(null));
        dispatch(fetchVersionList());
      },
      () => {},
    );
  };
}
export function deleteVersion(versionName) {
  return function (dispatch) {
    return apiFetch(
      `/api/version?configName=` + versionName,
      {
        method: 'DELETE',
      },
      dispatch,
      'Deleting: ' + versionName,
      (_json) => {
        dispatch(fetchVersionList());
      },
      () => {
        dispatch(fetchVersionList());
      },
    );
  };
}
