// import { createStore, applyMiddleware } from "redux";
import {
  configureStore,
  // , applyMiddleware
} from '@reduxjs/toolkit';
// import thunk from 'redux-thunk';
import rootReducer from './reducers';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
// const store = configureStore(rootReducer, applyMiddleware(thunk));

export default store;
