import React from 'react';
import { connect } from 'react-redux';
import { selectUser, setUserOpResult, updateUser } from '../redux/userActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

function makeAccess(accessLevel) {
  return {
    basic: (accessLevel & 0x1) !== 0,
    editUsers: (accessLevel & 0x4) !== 0,
    editVersion: (accessLevel & 0x800) !== 0,
    production: (accessLevel & 0x400) !== 0,
  };
}

function remakeAccess(props) {
  return (
    (props.basic ? 0x1 : 0) |
    (props.editUsers ? 0x4 : 0) |
    (props.editVersion ? 0x800 : 0) |
    (props.production ? 0x400 : 0)
  );
}
class UpdateUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      userPwd: '',
      userLogin: '',
      basic: true,
      production: false,
      editUsers: false,
      editVersion: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.selected == null && this.props.selected != null) {
      let accessLevel = 0;
      if (!this.props.selected.AccessLevel) accessLevel = 0;
      this.setState({
        ...this.state,
        userName: this.props.selected.Name,
        userLogin: this.props.selected.Login,
        ...makeAccess(accessLevel),
      });
    }
  }

  handleSubmit = () => {
    this.props.updateUser(
      this.state.userName,
      this.state.userLogin,
      this.state.userPwd,
      remakeAccess(this.state),
    );
  };

  handleNameChange = (event) => {
    this.setState({ ...this.state, userName: event.target.value });
  };
  handlePwdChange = (event) => {
    this.setState({ ...this.state, userPwd: event.target.value });
  };
  handleLoginChange = (event) => {
    this.setState({ ...this.state, userLogin: event.target.value });
  };

  handleClose = () => {
    this.props.selectUser(null);
  };

  handleClearOpResult = () => {
    this.props.setUserOpResult(null);
  };
  handleBasic = (e) => {
    console.log(e.target.checked);
    this.setState({ ...this.state, basic: e.target.checked });
  };
  handleEditUsers = (e) => {
    this.setState({ ...this.state, editUsers: e.target.checked });
  };
  handleEditVersion = (e) => {
    this.setState({ ...this.state, editVersion: e.target.checked });
  };
  handleProduction = (e) => {
    this.setState({ ...this.state, production: e.target.checked });
  };
  render() {
    return (
      <div>
        <Dialog
          open={this.props.selected != null}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="simple-dialog-title">User</DialogTitle>
          <DialogContent>
            <DialogContentText>Login must be unique</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="login"
              label="User Login"
              type="text"
              value={this.state.userLogin}
              onChange={this.handleLoginChange}
              fullWidth
            />
            <TextField
              margin="dense"
              id="name"
              label="User Name"
              type="text"
              value={this.state.userName}
              onChange={this.handleNameChange}
              fullWidth
            />
            <TextField
              margin="dense"
              id="password"
              label="User Password"
              type="text"
              value={this.state.userPwd}
              onChange={this.handlePwdChange}
              fullWidth
            />
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={this.state.basic} onChange={this.handleBasic} />}
                label="Basic"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={this.state.editUsers} onChange={this.handleEditUsers} />
                }
                label="Edit Users"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={this.state.editVersion} onChange={this.handleEditVersion} />
                }
                label="Edit Version"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={this.state.production} onChange={this.handleProduction} />
                }
                label="Production"
              />
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSubmit} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={this.props.opResult != null}
          message={this.props.opResult}
          autoHideDuration={6000}
          onClose={this.handleClearOpResult}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { ...state.user };
};

export default connect(mapStateToProps, { selectUser, setUserOpResult, updateUser })(UpdateUser);
