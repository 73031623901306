import { REGISTER_OPEN } from '../actionTypes';

const initialState = {
  open: false,
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case REGISTER_OPEN: {
      return {
        ...state,
        open: action.payload,
      };
    }
    default:
      return state;
  }
}
