import {
  SET_VERSION_LIST,
  SET_SHOW_VERSION_LIST,
  SET_SELECTED_VERSION,
  PICTURE,
} from '../actionTypes';

const initialState = {
  versionList: null,
  showVersionList: false,
  selected: null,
  picture: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_VERSION_LIST: {
      return {
        ...state,
        versionList: action.payload,
      };
    }
    case SET_SHOW_VERSION_LIST:
      return {
        ...state,
        showVersionList: action.payload,
      };
    case SET_SELECTED_VERSION:
      return {
        ...state,
        selected: action.payload,
      };
    case PICTURE:
      return {
        ...state,
        picture: action.payload,
      };
    default:
      return state;
  }
}
