import React from 'react';
import { connect } from 'react-redux';
import { selectDevice } from '../redux/actions';
import { fetchDump, uploadDump, linkDeviceAndUser } from '../redux/deviceActions';
import { searchRecords } from '../redux/purchaseRecord';
import { withStyles } from '@material-ui/core/styles';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Dropzone from 'react-dropzone';
import Stripe from '../stripe.png';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { Icon } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { CloudDownloadOutlined } from '@material-ui/icons';
import { Link } from 'react-router-dom';

const styles = (theme) => ({
  root: {
    width: '100%',
    maxWidth: 460,
    backgroundColor: theme.palette.background.paper,
    margin: 'auto',
  },
  dropzone: {
    textAlign: 'center',
    backgroundImage: `url(${Stripe})`,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  titleOne: {
    textAlign: 'right',
  },
  titleTwo: {
    flexGrow: 1,
    textAlign: 'left',
  },
});

// const Test = () => {
//   const [newUserId, setNewUserId] = useState('');
//   const [liked, setLiked] = useState('');
//   const dispatch = useDispatch();
//   const handleDump = () => {
//     dispatch(fetchDump('prot', this.props.selected.DeviceId));
//   };
//   const handleDumpJson = () => {
//     dispatch(fetchDump('json', this.props.selected.DeviceId));
//   };
//   const handleBack = () => {
//     dispatch(selectDevice(null));
//   };
//   const handleReset = () => {
//     this.props.linkDeviceAndUser('Reset Device', this.props.selected.DeviceId);
//     dispatch()
//   };

//   handleImposter = () => {
//     this.props.linkDeviceAndUser(
//       'Re-Linking Device',
//       this.props.selected.DeviceId,
//       this.state.newUserId,
//     );
//   };
//   handleUserIdInput = (e) => {
//     this.setState({ ...this.state, newUserId: e.target.value });
//   };

//   uploadDump = (acceptedFiles) => {
//     acceptedFiles.forEach((file) => {
//       const reader = new FileReader();

//       let isJson = file.name.includes('.json');

//       reader.onabort = () => console.log('file reading was aborted');
//       reader.onerror = () => console.log('file reading has failed');
//       reader.onload = () => {
//         console.log(reader);
//         // Do whatever you want with the file contents
//         const binaryStr = reader.result;

//         this.props.uploadDump(this.props.selected.DeviceId, btoa(binaryStr), isJson);
//       };
//       reader.readAsBinaryString(file);
//     });
//   };

//   handleLikeDevice = () => {
//     let k = localStorage.getItem('Liked_' + this.props.selected.DeviceId);
//     console.log(k);
//     if (k === null || k === 'False') {
//       localStorage.setItem('Liked_' + this.props.selected.DeviceId, 'True');
//       this.setState({ ...this.state, liked: true });
//       console.log(this.state);
//     } else {
//       localStorage.setItem('Liked_' + this.props.selected.DeviceId, 'False');
//       this.setState({ ...this.state, liked: false });
//       console.log(this.state);
//     }
//   };
//   isLiked = (deviceId) => {
//     return localStorage.getItem('Liked_' + deviceId) === 'True';
//   };
//   fetchIaps = () => {
//     this.props.searchRecords(null, this.props.selected.DeviceId);
//   };
//   return <></>;
// };

class DeviceActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = { newUserId: '', liked: this.isLiked(props.selected.DeviceId) };
  }

  handleDump = () => {
    this.props.fetchDump('prot', this.props.selected.DeviceId);
  };
  handleDumpJson = () => {
    this.props.fetchDump('json', this.props.selected.DeviceId);
  };
  handleBack = () => {
    this.props.selectDevice(null);
  };
  handleReset = () => {
    this.props.linkDeviceAndUser('Reset Device', this.props.selected.DeviceId);
  };

  handleImposter = () => {
    this.props.linkDeviceAndUser(
      'Re-Linking Device',
      this.props.selected.DeviceId,
      this.state.newUserId,
    );
  };
  handleUserIdInput = (e) => {
    this.setState({ ...this.state, newUserId: e.target.value });
  };

  uploadDump = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      let isJson = file.name.includes('.json');

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        console.log(reader);
        // Do whatever you want with the file contents
        const binaryStr = reader.result;

        this.props.uploadDump(this.props.selected.DeviceId, btoa(binaryStr), isJson);
      };
      reader.readAsBinaryString(file);
    });
  };

  handleLikeDevice = () => {
    let k = localStorage.getItem('Liked_' + this.props.selected.DeviceId);
    console.log(k);
    if (k === null || k === 'False') {
      localStorage.setItem('Liked_' + this.props.selected.DeviceId, 'True');
      this.setState({ ...this.state, liked: true });
      console.log(this.state);
    } else {
      localStorage.setItem('Liked_' + this.props.selected.DeviceId, 'False');
      this.setState({ ...this.state, liked: false });
      console.log(this.state);
    }
  };
  isLiked = (deviceId) => {
    return localStorage.getItem('Liked_' + deviceId) === 'True';
  };
  fetchIaps = () => {
    this.props.searchRecords(null, this.props.selected.DeviceId);
  };
  render() {
    const { classes } = this.props;
    return (
      <div>
        <AppBar position="static">
          <Toolbar>
            <IconButton aria-label="back" color="inherit" onClick={this.handleBack}>
              <ArrowBackIosIcon />
            </IconButton>
            <Hidden mdDown>
              <Typography variant="h6" className={classes.titleTwo}>
                {this.props.selected.Name}
              </Typography>
            </Hidden>
            <Typography variant="subtitle2" className={classes.titleOne}>
              {this.props.selected.DeviceId}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <List component="nav" aria-label="main mailbox folders">
            <ListItem button onClick={this.handleLikeDevice}>
              <ListItemIcon>
                {this.state.liked ? <FavoriteIcon color="primary" /> : <FavoriteBorderIcon />}
              </ListItemIcon>
              <ListItemText primary="Pin Device" />
            </ListItem>
            <ListItem button onClick={this.handleDump}>
              <ListItemIcon>
                <CloudDownloadIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Download Dump File (PROT)" />
            </ListItem>
            <ListItem button onClick={this.handleDumpJson}>
              <ListItemIcon>
                <CloudDownloadOutlined />
              </ListItemIcon>
              <ListItemText primary="Download Dump File (JSON)" />
            </ListItem>

            <Divider />
            <ListItem className={classes.dropzone}>
              <Dropzone onDrop={(acceptedFiles) => this.uploadDump(acceptedFiles)}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <CloudUploadIcon />
                      <p>Drag 'n' drop one Dump json here</p>
                    </div>
                  </section>
                )}
              </Dropzone>
            </ListItem>
            <Divider />
            <ListItem button onClick={this.handleReset}>
              <ListItemIcon>
                <DeleteForeverIcon />
              </ListItemIcon>
              <ListItemText primary="Reset Data" />
            </ListItem>
            <Divider />
            <ListItem>
              <TextField
                value={this.state.needIterate}
                onChange={this.handleUserIdInput}
                id="outlined-basic"
                label="Re-Link UserId"
              />

              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="comments" onClick={this.handleImposter}>
                  <Icon>send</Icon>
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Link to={'/purchases'}>
              <ListItem button onClick={this.fetchIaps}>
                <ListItemIcon>
                  <ShoppingCartIcon />
                </ListItemIcon>
                <ListItemText primary="Find Purchases" />
              </ListItem>
            </Link>
          </List>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { ...state.devices };
};

export default connect(mapStateToProps, {
  selectDevice,
  fetchDump,
  uploadDump,
  linkDeviceAndUser,
  searchRecords,
})(withStyles(styles)(DeviceActions));
