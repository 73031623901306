import React, { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useJwt } from 'react-jwt';
import { GoogleLogin } from 'react-google-login';
import { register } from '../redux/actions';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
export const RegistrationPage = () => {
  const dispatch = useDispatch();
  const registration = useSelector((state) => state.registration);
  const [cookies, setCookie] = useCookies(['auth']);
  const [result, setResult] = useState();
  const navigate = useNavigate();
  const { decodedToken } = useJwt(cookies.auth);
  let [complit, setComplit] = useState(false);
  let [sindIn, setSindIn] = useState(false);
  let shotContiniu = useCallback(() => {
    if (complit && sindIn) {
      dispatch(register(true));
    }
  }, [complit, sindIn, dispatch]);
  const check = async () => {
    const res = await axios
      .get('/api/verifyUser', { withCredentials: true })
      .then((res) => {
        setResult(res.data.validUser);
        console.log(res);
      })
      .catch((err) => {
        setResult(false);
        console.log(err);
      });
    return res;
  };
  const onSuccess = (res) => {
    setCookie('auth', res.getAuthResponse().id_token);
    if (res.getAuthResponse().id_token) {
      setSindIn(true);
      setResult(true);
      shotContiniu();
      console.log(decodedToken);
    }
  };
  useEffect(() => {
    console.log(decodedToken);
    check();
  });
  useEffect(() => {
    console.log(result);
    if (result === true) {
      setSindIn(true);
      shotContiniu();
    }
    console.log(result);
  }, [result, shotContiniu]);

  useEffect(() => {
    setComplit(true);
    shotContiniu();
    if (registration.open === true) {
      if (result === true) {
        navigate('/app');
        dispatch(register(false));
      }
    }
  }, [shotContiniu, navigate, result, dispatch, registration.open]);
  // const responseGoogle = (response) => {
  //   console.log(response);
  // };
  const onFailure = (err) => {
    console.log('failed', err);
  };
  return (
    <div className="googl-login-chapter">
      <div className="googl-login-block">
        {/* {result === false ? <h2 style={{ color: 'red' }}>error 401</h2> : null} */}
        <h3 style={{ color: '#fff' }}>Login with google</h3>
        <GoogleLogin
          className="googl-login"
          clientId="676752045922-e273p3ltmv65hemjiq4cjpan0iisq25k.apps.googleusercontent.com"
          buttonText="Sign in with Google"
          onSuccess={onSuccess}
          onFailure={onFailure}
          cookiePolicy={'single_host_origin'}
          // isSignedIn={true}
          // responseGoogle={responseGoogle}
        />
      </div>
    </div>
  );
};
