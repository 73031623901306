import { DialogContentText } from '@material-ui/core';
import { Button, Dialog, DialogActions, DialogContent, Input } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import React from 'react';
import { setOpen, setFile, fetchPicture } from '../redux/pictureSlice';
export const AddPicture = () => {
  const selector = useSelector((state) => state.pictureSlice);
  const dispatch = useDispatch();
  const handleFileChange = (e) => {
    if (e.target.files) {
      dispatch(setFile(e.target.files[0]));
    }
  };

  return (
    <Dialog open={selector.open}>
      <DialogContent>
        <DialogContentText>set picture</DialogContentText>
      </DialogContent>
      {/* <Input
        sx={{ padding: '10px' }}
        placeholder="enter file name"
        value={selector.fileName}
        onChange={(e) => dispatch(setFileName(e.target.value))}
      /> */}
      <Input
        sx={{ marginTop: '20px', padding: '10px' }}
        style={{ backgroundColor: '#fff' }}
        accept=".jpg, .jpeg, .png"
        onChange={(e) => {
          handleFileChange(e);
        }}
        placeholder="you need to choose a photo"
        type="file"
      />
      <DialogActions>
        <Button color="primary" onClick={() => dispatch(setOpen(false))}>
          cancel
        </Button>
        <Button
          color="primary"
          onClick={() => {
            dispatch(fetchPicture(selector));
            dispatch(setOpen(false));
          }}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
