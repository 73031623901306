import { OPEN_MODAL_PICTURE } from '../actionTypes';

const initialState = {
  open: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case OPEN_MODAL_PICTURE: {
      //const { id } = action.payload;
      return {
        ...state,
        open: action.payload,
      };
    }
    default:
      return state;
  }
}
