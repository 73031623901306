export const getDate = (decodedToken) => {
  let date = new Date(decodedToken?.exp * 1000);
  let hours = date.getHours();
  let minutes = '0' + date.getMinutes();
  let seconds = '0' + date.getSeconds();
  let formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
  return formattedTime;
};

export const getDateRefresh = (decodedToken) => {
  var now = Math.floor(Date.now());
  let date = Math.floor(decodedToken * 1000);
  let formattedTime = date - now;
  return formattedTime;
};
