import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { connect } from 'react-redux';
import { displayOpResultComplete } from '../redux/asyncOperationActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

class OperationDisplay extends React.Component {
  handleClose = () => {
    this.props.displayOpResultComplete();
  };

  render() {
    console.log('r:', this.props);
    let op = this.props.displayOpResult;
    let description;
    let message;
    let resultElem;
    let actions;
    let className;
    if (op == null && this.props.operations.length > 0) {
      op = this.props.operations[0];
    }

    if (op != null) {
      description = op.description;
      message = op.resultMessage;
      if (op.pending) {
        resultElem = <CircularProgress />;
      } else {
        if (op.result) {
          className = 'async-op-ok';
          resultElem = <CheckCircleOutlineIcon style={{ fontSize: 60 }} />;
          if (!this.interval) {
            let v = this;
            this.interval = setTimeout(() => {
              v.interval = null;
              v.props.displayOpResultComplete();
            }, 700);
          }
        } else {
          message = 'Error:' + message;
          className = 'async-op-fail';
          resultElem = <HighlightOffIcon style={{ fontSize: 60 }} />;
          actions = (
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          );
        }
      }
    }

    return (
      <div>
        <Dialog
          open={op != null}
          TransitionComponent={Transition}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          disableBackdropClick={false}
          disableEscapeKeyDown={false}
          className={className}>
          <DialogTitle id="alert-dialog-title" className={'fetching-dialog-title'}>
            {description}
          </DialogTitle>
          <DialogContent className={'fetching-dialog-content'}>
            {resultElem}
            <DialogContentText id="alert-dialog-slide-description">{message}</DialogContentText>
          </DialogContent>
          {actions}
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { ...state.asyncOperation };
};

export default connect(mapStateToProps, { displayOpResultComplete })(OperationDisplay);
