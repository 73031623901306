import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  open: false,
  makeRequest: true,
  loader: false,
  pictureArrName: null,
  receivedPhoto: null,
  url: null,
  fileName: '',
  file: null,
};
export const fetchPictureLink = createAsyncThunk(
  'tasks/fetchTasks',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      // fetchPictureLink
      const res = await axios
        .get('/api/publicImageUrlPrefix')
        .then((res) => {
          console.log(res);
          dispatch(setUrl(res.data.urlPrefix));
        })
        .catch((err) => console.log(err));
      return res;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.message);
    }
  },
);
export const fetchPictureArrName = createAsyncThunk(
  'tasks/fetchPictureArrName',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const res = axios
        .get(`api/imageList`, { withCredentials: true })
        .then((response) => {
          console.log(response.data);
          dispatch(setPictureArrName(response.data));
          dispatch(setMakeRequest(false));
        })
        .catch((error) => {
          console.log(error);
        });
      return res;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.message);
    }
  },
);
export const fetchPicture = createAsyncThunk(
  'tasks/fetchPicture',
  async (selector, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoader(true));
      const res = await fetch(
        `/api/image?configName=${
          selector.fileName !== '' ? selector.fileName : selector.file.name
        }`,
        {
          method: 'POST',
          body: selector.file,
          headers: {
            'content-type': selector.file.type,
            'content-length': `${selector.file.size}`,
          },
        },
      )
        .then((res) => {
          dispatch(setMakeRequest(true));
          return res.json();
        })
        .then((data) => {
          dispatch(setPictureArrNameAdd(data.name));
          console.log(data);
        })
        .catch((err) => {
          dispatch(setLoader(false));
        })
        .finally(() => {
          dispatch(setMakeRequest(false));
          dispatch(setLoader(false));
        });
      return res;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.message);
    }
  },
);
export const fetchDownloadPicture = createAsyncThunk(
  'tasks/fetchDownloadPicture',
  async (url, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoader(true));
      fetch(url, {
        mode: 'no-cors',
        withCredentials: true,
      })
        .then((response) => {
          console.log(response);
          return response.blob();
        })
        .then((blob) => {
          let blobUrl = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.download = url.replace(/^.*[\\]/, '');
          a.href = blobUrl;
          document.body.appendChild(a);
          a.click();
          a.remove();
          console.log('iam yellow');
          dispatch(setLoader(false));
        })
        .catch((e) => {
          dispatch(setLoader(false));
          console.log(e);
        });
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.message);
    }
  },
);
export const fetchDeletePicture = createAsyncThunk(
  'tasks/fetchDeletePicture',
  async (e, { rejectWithValue, dispatch }) => {
    try {
      console.log(e);
      dispatch(setLoader(true));
      const res = await axios
        .delete(`api/image?configName=${e}`)
        .then(() => dispatch(setMakeRequest(true)))
        .then(() => {
          dispatch(setLoader(false));
          // dispatch(setMakeRequest(false));
          dispatch(setPictureArrNameDelete(e));
        })
        .catch((e) => {
          dispatch(setLoader(false));
          console.log(e);
        });
      return res;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.message);
    }
  },
);
export const pictureSlice = createSlice({
  name: 'picture',
  initialState,
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload;
    },
    setMakeRequest: (state, action) => {
      state.makeRequest = action.payload;
    },
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setPictureArrName: (state, action) => {
      state.pictureArrName = action.payload;
    },
    setPictureArrNameAdd: (state, action) => {
      if (state.pictureArrName === null) {
        return;
      }
      // for (let i = 0; i < state.pictureArrName.length - 1; i++) {
      //   for (let j = i + 1; j < state.pictureArrName.length; j++) {
      //     if (state.pictureArrName[i] === state.pictureArrName[j]) {
      //       return;
      //     }
      //   }
      // }
      state.pictureArrName.push(action.payload);
    },
    setPictureArrNameDelete: (state, action) => {
      for (let i = 0; i < state?.pictureArrName?.length; i++) {
        if (state?.pictureArrName[i] === action?.payload) {
          delete state?.pictureArrName[i];
          return;
        }
      }
    },

    setReceivedPhoto: (state, action) => {
      state.receivedPhoto = action.payload;
    },
    setUrl: (state, action) => {
      state.url = action.payload;
    },
    setFileName: (state, action) => {
      state.fileName = action.payload;
    },
    setFile: (state, action) => {
      state.file = action.payload;
    },
  },
});

export const {
  setOpen,
  setMakeRequest,
  setLoader,
  setPictureArrName,
  setReceivedPhoto,
  setUrl,
  setFileName,
  setFile,
  setPictureArrNameAdd,
  setPictureArrNameDelete,
} = pictureSlice.actions;

export default pictureSlice.reducer;
