import { START_ASYNC_OP, DISPLAY_OP_RESULT, DISPLAY_OP_RESULT_COMPLETE } from '../actionTypes';

const initialState = {
  operations: [],
  resultToDisplay: [],
  displayOpResult: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case START_ASYNC_OP: {
      return {
        ...state,
        operations: [...state.operations, { ...action.payload, result: null }],
      };
    }
    case DISPLAY_OP_RESULT:
      if (state.displayOpResult != null) {
        return {
          ...state,
          resultToDisplay: [...state.resultToDisplay, action.payload],
        };
      }
      return {
        ...state,
        displayOpResult: action.payload,
      };

    case DISPLAY_OP_RESULT_COMPLETE:
      let ns = { ...state };
      let dr = ns.displayOpResult;
      if (dr != null) {
        ns.operations = state.operations.filter((e) => e.opId !== dr.opId);
        ns.resultToDisplay = state.resultToDisplay.filter((e) => e.opId !== dr.opId);
      }
      ns.displayOpResult = null;
      if (state.resultToDisplay.length > 0) {
        ns.displayOpResult = state.resultToDisplay[0];
      }
      return ns;
    default:
      return state;
  }
}
