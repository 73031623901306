import React from 'react';
import './App.css';
// import { connect } from 'react-redux';
import DeviceList from './components/DeviceList';
import UpdateUser from './components/UpdateUser';
import ErrorBoundary from './components/ErrorBoundary';
import { getDateRefresh } from './other/time';
import { useEffect } from 'react';
import { useJwt } from 'react-jwt';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';

const App = () => {
  const [cookies, _, removeCookie] = useCookies(['auth']);
  const navigate = useNavigate();
  const { decodedToken } = useJwt(cookies.auth);
  const intervalRef = useRef();
  //This is a temporary fix that needs to be changed.
  useEffect(() => {
    if (
      typeof getDateRefresh(decodedToken?.exp) === 'number' &&
      getDateRefresh(decodedToken?.exp) !== 0 &&
      !isNaN(getDateRefresh(decodedToken?.exp))
    ) {
      console.log(Math.floor(decodedToken?.exp * 1000));
      intervalRef.current = setInterval(() => {
        removeCookie('auth');
        navigate('/');
        alert('you have run out of session time, go back to the application to continue');
      }, getDateRefresh(decodedToken?.exp) - 5000);
    }
    return () => clearInterval(intervalRef.current);
  }, [decodedToken, navigate, removeCookie, _]);
  //

  return (
    <>
      <ErrorBoundary>
        <DeviceList />
        <UpdateUser />
      </ErrorBoundary>
    </>
  );
};

// const mapStateToProps = (state) => {
//   console.log(state);
//   return {};
// };

// export default connect(mapStateToProps)(App);
export default App;
